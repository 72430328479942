import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Accordion from "./accordian";
import { useDispatch, useSelector } from "react-redux";
import { getFeedback } from "../../store/action/feedback-action";

import Contactus from "../../components/footer/contact-us";
import { FEEDBACK_ADDED } from "../../store/constant/post";
import notifications from "../notifications";

const Reviews = () => {
  const dispatch = useDispatch();
  const { feedbackList } = useSelector((store) => store.feedbackDetails);
  const [countState, setCountState] = useState({
    open: 0,
    close: 0,
  });

  const [Open, setIsOpen] = useState(false);

  const { userInfo } = useSelector((state) => state.userDetails);

  const login = userInfo?.response?.login || "";
  const { feedbackAdded } = useSelector((state) => state.feedbackDetails);

  useEffect(() => {
    // close the modal when feedback sent
    if (feedbackAdded) {
      setIsOpen(false);
      dispatch({
        type: FEEDBACK_ADDED,
        payload: false,
      });
    }
  }, [feedbackAdded]);

  const handleModalOpen = () => {
    if (login) {
      setIsOpen(true);
      window.scrollTo({ top: 0, left: 0 });
    } else {
      notifications("error", "Please login to submit the form");
    }
  };

  useEffect(() => {
    const closeCount = feedbackList?.filter(
      (item) => item?.status === "close"
    ).length;
    const openCount = feedbackList?.length - closeCount;
    setCountState({
      close: closeCount,
      open: openCount,
    });
  }, [feedbackList]);

  useEffect(() => {
    dispatch(getFeedback());
  }, []);

  return (
    <div className="background ">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Reviews</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div className=" px-4 pt-16 lg:pt-28 pb-8 lg:pb-16 lg:px-24 ">
        <div className="bg-[#12181F] px-5 lg:px-10 lg:pb-10 pt-5 lg:pt-5 rounded-xl ">
          <div className="flex justify-between items-start ">
            <div className="flex flex-col">
              <p className="text-white font-mona_sans_thin  leading-[40px] text-[18px] lg:leading-[48px] lg:text-[30px]  lg:mb-0">
                Bug / Feature List
              </p>

              <div className="flex w-fit justify-start md:justify-start">
                <p className="font-nunito  text-[12px] leading-0 lg:text-[17px] lg:leading-[30px]  md:py-2">
                  <span className="text-red-400">{countState.open} open</span>{" "}
                  <span className="text-white">|</span>{" "}
                  <span className="text-green-400">
                    {countState.close} close
                  </span>
                </p>
              </div>
            </div>
            <div className="flex justify-end">
              <button
                onClick={handleModalOpen}
                className="bg-teal-600 hover:bg-teal-800 text-white text-[12px] lg:text-[16px] lg:font-[400]  transition-all duration-200 font-nunito px-4 py-2 rounded-lg mt-4"
              >
                Request a query
              </button>
            </div>
          </div>
          <p className="text-grey font-nunito  text-[12px] leading-[20px] lg:text-[17px] lg:leading-[30px] py-2 lg:pb-4">
            Help us improve! Report a bug or suggest a feature, We'll prioritize
            resolving them promptly.
          </p>

          <Accordion data={feedbackList} />
        </div>
        {Open && (
          <div className="fixed left-0 right-0 z-999 form">
            <Contactus setIsOpen={setIsOpen} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Reviews;
