import React from 'react'
import FillButton from "../../core/fill-button";
import "./Homepage.css";
import { useNavigate } from "react-router-dom";
const Banner = () => {
  const navigate = useNavigate();
  return (
    <div className="background ">
      <div className=" px-8 pt-24 lg:pt-36 pb-8 lg:pb-16 lg:px-24">
        <div data-aos="fade-up">
          <h1 className="text-white font-lato font-extrabold leading-[50px] text-[50px] lg:leading-[90px] lg:text-[75px]">
            An open platform to meet
            <br className="hidden lg:block" />
            your open-source contributor
          </h1>
          <p className="text-grey font-nunito  text-[15px] leading-[25px] lg:text-[17px] lg:leading-[40px] mt-8 mb-8 lg:mb-0 lg:mt-8">
            Discover open-source projects, connect with experienced maintainers,
            and <br className="hidden lg:block" /> collaborate with a community
            of passionate contributors. Discover your ideal project partner{" "}
            <br className="hidden lg:block" /> among our growing community of
            more than 100 members on the Project Buddy.
          </p>
        </div>

        <div className=" mt-6 lg:mt-10">
          <FillButton
            pysm="py-2"
            pxsm="px-4"
            py="lg:py-3"
            px="lg:px-8"
            onClick={() => navigate(`/explore-projects`)}
          >
            Explore Projects
          </FillButton>
        </div>
      </div>
    </div>
  );
};
export default Banner;
