import "./Homepage.css";
import DiscriptionCardWithSIdeImage from "./discriptions/discriptionCardWithSIdeImage";
import { useNavigate } from "react-router-dom";
import { motion, useInView } from "framer-motion";
import React, { useRef, useState, useEffect } from "react";
import Search from "../../core/searchBar";
import { useSelector } from "react-redux";
import notifications from "../notifications";
const ProfileCard = () => {
  const { userInfo } = useSelector((state) => state.userDetails);
  const navigate = useNavigate();
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [isMobile] = useState(() => window.innerWidth < 700);
  const [height, setHeight] = useState("");

  const viewProfile = () => {
    if (userInfo?.response?.login) {
      const login = userInfo?.response?.login;
      navigate(`/view-profile/${login}`);
    } else {
      notifications(
        "error",
        "Please login to use this feature or use search bar for external users."
      );
    }
  };

  useEffect(() => {
    if (isMobile) {
      setHeight("200px");
    } else {
      setHeight("354px");
    }
  }, [isMobile]);
  return (
    <div className=" px-8 pt-8 lg:pt-0 lg:px-24">
      <div className="flex  h-[218px] lg:h-[354px]">
        <div ref={ref} className="relative">
          {isInView && (
            <motion.div
              initial={{ height: "0px" }}
              animate={{ height: height }}
              exit={{ height: "0px" }}
              transition={{
                duration: "1.5",
              }}
              className=" h-[200px] lg:h-[354px] profileCardBackGround w-[5px] lg:w-[8px] mt-8 lg:mt-0 mr-3 lg:mr-5"
            ></motion.div>
          )}
          <img
            data-aos="fade-down"
            src="Images/circle.svg"
            className="text-white absolute  w-3 lg:w-5 left-[-3px] lg:left-[-5px] top-[0.5rem] lg:top-[9.25rem]"
          />
        </div>

        <div className="w-full mt-[0.15rem] lg:mt-[9rem] relative">
          <p
            data-aos="fade-left"
            data-aos-duration="4000"
            className="text-secondary font-nunito  text-[18px] lg:text-[30px] lg:leading-[30px]  "
          >
            Profile view
          </p>
          <h3
            data-aos="fade-left"
            data-aos-duration="6000"
            className="text-white font-lato font-bold   text-[25px] leading-[30px] lg:leading-[50px] lg:text-[40px] lg:w-6/12 mt-4 "
          >
            Your Analysis
          </h3>
          <p
            data-aos="fade-left"
            data-aos-duration="8000"
            className="text-grey text-[12px] lg:text-[15px] font-nunito  leading-[20px] lg:leading-[30px] lg:w-10/12 mt-8"
          >
            See where you are on your coder journey.
          </p>
          {/* <div className="mt-5 w-full md:w-1/2">
            <Search />
          </div> */}
        </div>
      </div>
      <DiscriptionCardWithSIdeImage
        buttonName="Your profile Analysis "
        imageName="Images/ProfileCardPNG.png"
        onClick={viewProfile}
        description={
          "Explore your profile insights like never before with our advanced Data Visualization tool. From commit trends to language proficiency and size-wise analysis, gain valuable insights that elevate your job prospects."
        }
        subDesc={"You can also download your easy-to-read report today!"}
      />
    </div>
  );
};

export default ProfileCard;
