exports.recentActivityQuery = (repo) => {
    const query = `
    query {
      repository(owner: "${repo.owner.login}", name: "${repo.name}") {
        defaultBranchRef {
          target {
            ... on Commit {
              history(first: 1) {
                edges {
                  node {
                    message
                    committedDate
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
    return query
}

exports.getCommitsQuery = (repo) => {
  const query = `
  query {
    repository(owner: "${repo.owner.login}", name: "${repo.name}") {
      defaultBranchRef {
        target {
          ... on Commit {
            history(first: 100) {
              totalCount
            }
          }
        }
      }
      stargazers {
        totalCount
      }
      forks {
        totalCount
      }
      languages(first: 1) {
        nodes {
          name
        }
      }
    }
  }
`;
  return query
}