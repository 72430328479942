import Homepage from "./components/homepage";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { LOGIN_SUCCESS } from "./store/constant/user";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import Layout from "./layout";
import FindMate from "./components/find-mates";
import ExploreProject from "./components/explore-project";
import "react-loading-skeleton/dist/skeleton.css";
import ViewProifle from "./components/view-profile";
import AOS from "aos";
import "aos/dist/aos.css";
import Reviews from "./components/reviews";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route path="" element={<Homepage />} />
      <Route path="find-mate" element={<FindMate />} />
      <Route path="explore-projects" element={<ExploreProject />} />
      <Route path="/reviews" element={<Reviews />} />
      <Route path="view-profile/:id" element={<ViewProifle />} />
    </Route>
  )
);

function App() {
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  if (userInfo) {
    dispatch({
      type: LOGIN_SUCCESS,
      payload: userInfo,
    });
  }

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="bg-[#0D1117] h-auto">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
