import "./Homepage.css";
import Textbutton from "../../core/text-button";
import { FaArrowRightLong } from "react-icons/fa6";
import AddProject from "../add-project";
import { motion, useInView } from "framer-motion";
import React, { useRef, useState, useEffect } from "react";

const BenefitsCard = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [isMobile] = useState(() => window.innerWidth < 700);
  const [height, setHeight] = useState("");
  useEffect(() => {
    if (isMobile) {
      setHeight("160px");
    } else {
      setHeight("400px");
    }
  }, [isMobile]);

  return (
    <div className=" px-8 pt-6 lg:pt-0 lg:px-24 ">
      <div className="flex h-[160px] lg:h-[400px] ">
        <div ref={ref} className="relative">
          {isInView && (
            <motion.div
              initial={{ height: "0px" }}
              animate={{ height: height }}
              exit={{ height: "0px" }}
              transition={{
                duration: "1.5",
              }}
              className=" h-[160px] lg:h-[400px] benefitsBackground w-[5px] lg:w-[8px] mt-8 lg:mt-0 mr-3 lg:mr-5"
            ></motion.div>
          )}

          <img
            data-aos="fade-down"
            src="Images/circle.svg"
            className="text-white absolute  w-3 lg:w-5 left-[-3px] lg:left-[-5px] top-2 lg:top-[149px]"
          />
        </div>

        <div className="w-full">
          <p
            data-aos="fade-left"
            data-aos-duration="4000"
            className="text-secondary font-nunito  text-[18px] lg:text-[30px] lg:leading-[30px] lg:mt-36"
          >
            Benefits
          </p>
          <h3
            data-aos="fade-left"
            data-aos-duration="6000"
            className="text-white font-lato font-bold   text-[25px] leading-[30px] lg:leading-[50px] lg:text-[40px] lg:w-6/12 mt-4 "
          >
            Add Projects
          </h3>
          <p
            data-aos="fade-left"
            data-aos-duration="7000"
            className="text-grey text-[12px] lg:text-[15px] font-nunito  leading-[20px] lg:leading-[30px] lg:w-6/12 mt-8"
          >
            Maximize your project's visibility, collaboration potential, and
            career opportunities
          </p>
        </div>
      </div>

      <div className=" pl-5 lg:pl-8 pt-8 lg:pt-[7rem] pb-8 lg:pb-[6rem] relative bg-[#12181F]  lg:w-11/12 lg:ml-[-1.75rem] mt-4 lg:mt-0 rounded-lg ">
        <p
          data-aos="fade-up"
          className=" text-grey font-nunito  text-[12px] leading-[20px] lg:text-[15px] lg:leading-[30px] lg:w-10/12"
        >
          Showcase, Analyze, and Land Jobs! Use our Data Visualization tool to
          highlight your profile, including previous commits, mastered coding
          languages, and more. Visually impress employers with your profile.
          Don't wait any longer, Add your projects now!
        </p>
        <div className="mt-8 lg:mt-24 flex-col lg:flex-row lg:flex items-start justify-between relative">
          <div className="bg-transparent  text-primary text-[12px] lg:text-[18px] lg:font-[400]  font-nunito">
            <div className="flex items-center">
              How to add Project &nbsp;&nbsp;
              <FaArrowRightLong className="text-white" />
            </div>
          </div>
          {/* <img
            src="Images/benefisSectionPNG.png"
            className="w-[16rem] lg:w-[34rem]  mt-3 lg:mt-[-3rem] absolute right-4"
          /> */}
          <div
            data-aos="fade-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
            className="absolute top-[37px] lg:top-[-56px] right-[10px] lg:right-[-64px] w-full lg:w-10/12 pointer-events-none "
          >
            <AddProject background={"#232C36"} show={false} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenefitsCard;
