import {
  GET_ALL_PROJECTS,
  POST_CREATED,
  ADD_TECH,
  CLEAR_TECH,
  CLEAR_PROJECT,
  GET_RESPONCE,
  AFTER_POST_CREATED,
  ONLY_MY_FEED,
} from "../constant/post";

const initialState = {
  allProjects: [],
  tech: [],
  postCreated: false,
  allProjectsResponce: false,
};

const postDetailsReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case GET_ALL_PROJECTS:
      return {
        ...state,
        allProjects: action.payload,
      };
    case CLEAR_PROJECT:
      return {
        ...state,
        allProjects: [],
      };
    case POST_CREATED:
      return {
        ...state,
        postCreated: action.payload,
      };
    case AFTER_POST_CREATED:
      return {
        ...state,
        postCreated: false,
      };
    case ADD_TECH:
      return {
        ...state,
        tech: action.payload,
      };
    case CLEAR_TECH:
      return {
        ...state,
        tech: [],
      };
    case ONLY_MY_FEED:
      return {
        ...state,
        myFeed:action.payload
      }
    case GET_RESPONCE:
      return {
        ...state,
        allProjectsResponce: action.payload,
      };

    default:
      return state;
  }
};

export default postDetailsReducer;
