import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getGraphData, getUserData } from "../../store/action/user-action";
import GitHub from '../../static/img/github.png'

import "./view.css";

import {
  CLEAR_GRAPH_DATA,
  CLEAR_USER_DATA,
  GRAPH_STATUS,
  USER_STATUS,
} from "../../store/constant/user";
// import EditProfile from "./edit-profile";
import { Helmet } from "react-helmet";
// import { exportComponentAsJPEG } from "react-component-export-image";

import DownloadReport from "./download";
import axios from "axios";
// import notifications from "../notifications";
import Banner from "./banner";
import RecentActivityCard from "./recent-activity-card";
import CommitaAnalysisCard from "./commit-analysis-card";
import ProjectsCards from "./projects-cards";
import CommitGraphCard from "./commit-graph-card";
import LanguageAnalaysisRow from "./language-analaysis-row";
import {getPublicRepos, getRecentActivities, getTotalCounts, langBasedOnBytes, repoBasedOnLanguage } from "../data";

const ViewProifle = ({ name, handleRemove, index, noCross }) => {
  const componentRef = useRef();
  const [userProfileName, setProfileName] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();
  const [dignal, setDignal] = useState(0);
  const [straight, setStraight] = useState(19);
  const [valdiUser, setValidUser] = useState("API Rate Limit Exceed try after sometime");
  const [isMobile] = useState(() => window.innerWidth < 700);

  const {
    userInfo: { response },
    user_profile,
    graphInfo,
    graphStatus,
    userStatus,
  } = useSelector((state) => state.userDetails);
  const {
    public_repos,
    followers,
    following,
    getTotalStar,
    forkCount,
    userInfo,
    commitGrpah,
  } = user_profile;

  const user = userInfo;

  function compareValue(a, b) {
    const value1 = a.value;
    const value2 = b.value;

    let comparison = 0;

    if (value1 > value2) {
      comparison = -1;
    } else if (value1 < value2) {
      comparison = 1;
    }
    return comparison;
  }
  let newLanguageBytes = [];
  let newLanguage = [];
  let newgetRepos = [];
  if (user_profile?.language || user_profile?.languageBytes) {
    newLanguageBytes = user_profile?.languageBytes.sort(compareValue);
    newLanguage = user_profile?.language.sort(compareValue);
    newgetRepos = user_profile?.getRepos.sort(compareValue);
  }

  useEffect(() => {
    if (!isMobile) {
      setDignal(0);
      setStraight(19);
    }
  }, [isMobile]);


  const getUserRawData = async () => {
    const urlParams = window.location.pathname.split("/view-profile/")[1];
    try {
      // Make call on GitHub Server to pull basic info about user
      const { data } = await axios.get(`https://api.github.com/users/${urlParams}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_COMMON_ACCESS_TOKEN}`,
        },
      });
      if (data) {
        // Extract details from it
        const { public_repos, followers, following } = data || {};
        let result = [];
        let page = 1;
        const perPage = 100; //

        while (true) {
          const response = await axios.get(`https://api.github.com/users/${urlParams}/repos`, {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_COMMON_ACCESS_TOKEN}`,
            },
            params: {
              per_page: perPage,
              page: page,
            },
          });

          if (response.status !== 200) {
            throw new Error(`Failed to fetch repositories for user ${user}`);
          }
          const repos = response.data;
          result = result.concat(repos);
          const linkHeader = response.headers.link;
          if (!linkHeader || !linkHeader.includes('rel="next"')) break; // No more pages
          page++;
        }
        const repos = result?.filter((rec) => rec.size > 0);
        const response = { repos, public_repos, followers, following, data, }
        return response
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchGithubData = async () => {
    try {
      getUserRawData().then(async (res) => {
        const { public_repos, followers, following, repos, data } = res || {}
        const allNonEmptyRepo = repos
        // Passing the data to helper functions to get totalCounts and transformed data
        const getTotalStar = getTotalCounts(allNonEmptyRepo, "stargazers_count");
        const watchCount = getTotalCounts(allNonEmptyRepo, "watchers_count");
        const forkCount = getTotalCounts(allNonEmptyRepo, "forks_count");
        const language = repoBasedOnLanguage(allNonEmptyRepo);
        const languageBytes = langBasedOnBytes(allNonEmptyRepo);
        const getRepos = await getPublicRepos(allNonEmptyRepo, process.env.REACT_APP_COMMON_ACCESS_TOKEN);
        const recentActivites = await getRecentActivities(allNonEmptyRepo, process.env.REACT_APP_COMMON_ACCESS_TOKEN);
        const dataStream = {
          public_repos,
          followers,
          following,
          getTotalStar,
          watchCount,
          forkCount,
          language,
          languageBytes,
          getRepos,
          recentActivites,
          userInfo: data
        }
        dispatch(getUserData(dataStream))
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchGithubData()
    const urlParams = window.location.pathname.split("/view-profile/")[1];
    (async () => {
      try {
        const data = await axios.get(
          `https://api.github.com/users/${urlParams}`
        );

        if (data?.status == 200 && data?.data?.id) {
          setValidUser("API Rate Limit Exceed try after sometime");
          setProfileName(urlParams);
          if (id != user?.login) {
            dispatch({ type: CLEAR_USER_DATA });
            // dispatch(getUserData(id));
          }         
          dispatch(getGraphData(urlParams));
        } else {
          setValidUser(`Oops please check Github username again`);
          // notifications("error", "Oops please check Github username again");
        }
      } catch (error) {
        setValidUser("Oops please check Github username again");
        // notifications("error", "Oops please check Github username again");
      }
    })();

    return () => {
      dispatch({ type: CLEAR_USER_DATA });
      dispatch({ type: CLEAR_GRAPH_DATA });
      dispatch({ type: USER_STATUS, payload: "200" });
      dispatch({ type: GRAPH_STATUS, payload: "" });
    };
  }, [id]);

  return (
    <div className="">
      {userStatus === "200" && <DownloadReport componentRef={componentRef} />}
      
      <div className="background " ref={componentRef}>
        <Helmet>
          <meta charSet="utf-8" />
          <title> {`${userProfileName} Profile Analysis`} </title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
        {userStatus === "500" || valdiUser === "Oops please check Github username again" ? (
          <div className=" px-4 h-screen w-screen flex justify-end items-center lg:px-24 ">
            <div className="bgImg flex justify-center items-center w-full pl-5 pr-5 lg:pr-16 pt-5 lg:pt-0 lg:pl-16  ">
              <p className="font-nunito leading-[35px] lg:leading-[48px] text-[20px] text-center text-emerald-300">
                <img className="m-auto mb-4 w-40" src={GitHub}/>
                {valdiUser}
              </p>
            </div>
          </div>
        ) : (
          <div className=" px-4 pt-16 lg:pt-28 pb-8 lg:pb-16 lg:px-24 ">
            <div className="bg-[#12181F]  lg:pb-10  rounded-xl ">
              <Banner
                user={user}
                followers={followers}
                following={following}
                public_repos={public_repos}
                user_profile={user_profile}
                getTotalStar={getTotalStar}
                forkCount={forkCount}
              />
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-12 mt-12 p-2 lg:p-8 ">
                <RecentActivityCard user_profile={user_profile} user={user} />
                <CommitaAnalysisCard
                  user_profile={user_profile}
                  dignal={dignal}
                  straight={straight}
                  isMobile={isMobile}
                  newgetRepos={newgetRepos}
                />
              </div>

              <LanguageAnalaysisRow
                user_profile={user_profile}
                dignal={dignal}
                straight={straight}
                isMobile={isMobile}
                newLanguage={newLanguage}
                newLanguageBytes={newLanguageBytes}
              />
              <ProjectsCards user_profile={user_profile} />

              <CommitGraphCard
                graphInfo={graphInfo}
                graphStatus={graphStatus}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewProifle;
