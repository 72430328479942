import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { RiLogoutCircleLine } from "react-icons/ri";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dropdown({ avatar, name, handleSignOut, login }) {
  return (
    <Menu as="div" className="relative  text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center items-center gap-x-1.5 rounded-md   text-sm font-semibold text-[#1f2937] shadow-sm   ">
          <div className="rounded-full	border-2 border-white w-[50px] h-fit flex justify-center items-center overflow-hidden mr-2">
            <img src={avatar} className="w-[45px] h-[45px] rounded-full" />
          </div>
          <p className="font-nunito  text-[15px] font-bold text-white leading-[30px]">
            {name}
          </p>
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-[#1f2937]"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-[13rem]  origin-top-right rounded-md bg-black shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to={`/view-profile/${login}`}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-white",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  <div className="flex items-center">
                    <CgProfile /> &nbsp; My Profile
                  </div>
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-white",
                    "block px-4 py-2 text-sm"
                  )}
                  onClick={handleSignOut}
                >
                  <div className="flex items-center">
                    <RiLogoutCircleLine /> &nbsp; Logout
                  </div>
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
