import React from 'react'
import Textbutton from "../../../core/text-button";
import { FaArrowRightLong } from "react-icons/fa6";
const DiscriptionCardWithSIdeImage = ({
  buttonName,
  imageName,
  onClick,
  description,
  subDesc,
}) => {
  return (
    <div className=" pl-5 lg:pl-8 pt-8 lg:pt-[7rem] pb-8 lg:pb-[6rem] relative bg-[#12181F]  lg:w-11/12 lg:ml-[-1.75rem] mt-4 lg:mt-0 rounded-lg">
      <img
        data-aos="fade-left"
        src={imageName}
        className="w-24 lg:w-60 absolute right-[-8px] lg:right-[77px] top-[0px] lg:top-[31px]"
      />
      <p
        data-aos="fade-up"
        className=" text-grey font-nunito  text-[12px] leading-[20px] lg:text-[15px] lg:leading-[30px] w-8/12"
      >
        {description}
      </p>
      {subDesc && (
        <p
          data-aos="fade-up"
          className=" text-grey font-nunito  text-[12px] leading-[20px] lg:text-[15px] lg:leading-[30px] w-8/12 mt-1 md:mt-4"
        >
          {subDesc}
        </p>
      )}
      <div className="mt-7 lg:mt-14 ">
        <Textbutton onClick={onClick}>
          <div className="flex items-center">
            {buttonName} &nbsp;&nbsp;
            <FaArrowRightLong className="text-white" />
          </div>
        </Textbutton>
      </div>
    </div>
  );
};

export default DiscriptionCardWithSIdeImage;
