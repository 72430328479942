import React from 'react'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import MyResponsivePie from "./pie-graph";

const CommitaAnalysisCard = ({
  user_profile,
  dignal,
  straight,
  isMobile,
  newgetRepos,
}) => {
  return (
    <div className="">
      <p className="text-white  font-bold font-mona_sans_thin  leading-[20px] text-[12px] lg:leading-[20px] lg:text-[16px]">
        {user_profile?.getRepos?.length > 0 ? "Commit analysis" : "No Commits"}
      </p>
      <div className=" rounded-xl border border-solid border-[#070707] bg-backGroundCard shadow-[0px_0px_5px_0px_#FFFFFF29] mt-4">
        {user_profile?.getRepos ? (
          <MyResponsivePie
            dignal={dignal}
            straight={straight}
            isMobile={isMobile}
            data={user_profile?.getRepos}
          />
        ) : (
          <SkeletonTheme baseColor="#202020" highlightColor="#444">
            <Skeleton className="h-[28rem]" />
          </SkeletonTheme>
        )}

        {newgetRepos.length > 0 && (
          <p className="text-white text-center font-nunito  text-[12px] leading-[20px] lg:text-[17px] lg:leading-[30px] pl-4 pb-4">
            Most Commits are done in{" "}
            <span className="text-primary font-bold">
              {" "}
              {newgetRepos[0]?.label}{" "}
              {`(${newgetRepos[0].totalCommit} commits)`}
            </span>
          </p>
        )}
      </div>
    </div>
  );
};

export default CommitaAnalysisCard;
