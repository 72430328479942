import "./Homepage.css";
import Contributors from "../../core/contributors";
import { useSelector } from "react-redux";
import { motion, useInView } from "framer-motion";
import React, { useRef, useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import OfficialIcon from "../../static/img/official.png";
import "./Homepage.css";

const ContributorCard = () => {
  const { allUsers } = useSelector((state) => state.userDetails);
  const navigate = useNavigate();
  // limitedUsers is use to split the data till 8 results on homepage
  const limitedUsers = allUsers?.slice(0, 11) || [];
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [isMobile] = useState(() => window.innerWidth < 700);
  const [height, setHeight] = useState("");
  useEffect(() => {
    if (isMobile) {
      setHeight("148px");
    } else {
      setHeight("380px");
    }
  }, [isMobile]);
  return (
    <div>
      <div className=" px-8 pt-8 lg:pt-0 lg:px-24">
        <div className="flex  h-[148px] lg:h-[380px]">
          <div ref={ref} className="relative">
            {isInView && (
              <motion.div
                initial={{ height: "0px" }}
                animate={{ height: height }}
                exit={{ height: "0px" }}
                transition={{
                  duration: "1.5",
                }}
                className=" h-[148px] lg:h-[380px] contributorsCardBackGround w-[5px] lg:w-[8px] mt-8 lg:mt-0 mr-3 lg:mr-5"
              ></motion.div>
            )}
            <img
              data-aos="fade-down"
              src="Images/circle.svg"
              className="text-white absolute  w-3 lg:w-5 left-[-3px] lg:left-[-5px] top-[0.5rem] lg:top-[10.50rem]"
            />
          </div>

          <div className="w-full mt-[0.15rem] lg:mt-[10rem] relative">
            <p
              data-aos="fade-left"
              data-aos-duration="4000"
              className="text-secondary font-nunito  text-[18px] lg:text-[30px] lg:leading-[30px]  "
            >
              Contributors
            </p>
            <h3
              data-aos="fade-left"
              data-aos-duration="4000"
              className="text-white font-lato font-bold   text-[25px] leading-[30px] lg:leading-[50px] lg:text-[40px] lg:w-6/12 mt-4 "
            >
              Our Contributors
            </h3>
            <p
              data-aos="fade-left"
              data-aos-duration="4000"
              className="text-grey text-[12px] lg:text-[15px] font-nunito  leading-[20px] lg:leading-[30px] lg:w-10/12 mt-8"
            >
              Discover the impactful work of our contributors, driving
              innovation and progress in the tech world
            </p>
          </div>
        </div>
      </div>
      <div className="px-5 lg:px-16 lg:pb-10 hidden sm:flex flex-wrap justify-center sm:gap-5  md:gap-10">
        {!allUsers && <Contributors />}
        {limitedUsers?.map((item, index) => {
          return (
            <div data-aos="zoom-in-up" className="md:w-[18rem] lg:w-[20rem]">
              <Contributors data={item} />
            </div>
          );
        })}
      </div>
      <div className="flex flex-wrap justify-center sm:hidden mt-14 px-2  ">
        {limitedUsers?.map((item, index) => {
          return (
            <div className="cell-width">
              <div
                key={index}
                className="flex flex-col items-center my-2"
                onClick={() => navigate(`/view-profile/${item.login}`)}
              >
                <div className="rounded-full	border-2 border-white w-[38px] h-fit flex justify-center items-center overflow-hidden">
                  <img src={item.avatar} className="w-[35px] rounded-full" />
                </div>
                <p className="font-nunito mt-3 text-[13px] font-bold text-white leading-[20px]">
                  {["amirSohel007"].includes(item.login) && (
                    <img
                      style={{ width: 15 }}
                      className="inline-block ml-2"
                      src={OfficialIcon}
                    />
                  )}{" "}
                  {item.name}
                </p>
                <div className="flex">
                  <p className="font-nunito  text-[12px] font-bold text-grey leading-[20px] ml-2">
                    {item.visited || 0} seen
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="text-center w-full	mb-4">
        <NavLink
          to="/find-mate"
          className="bg-[#70C1CC] hover:bg-[#03a2b7] text-white text-[12px] lg:text-[16px] lg:font-[400]  transition-all duration-200 font-nunito px-4 py-2 rounded-lg "
        >
          View All
        </NavLink>
      </div>
    </div>
  );
};

export default ContributorCard;
