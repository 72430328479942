import React from 'react'

const TextButton = ({ children, pysm, pxsm, px, py, onClick }) => {
  pysm = pysm ? pysm : py;
  pxsm = pxsm ? pxsm : px;

  const css = `bg-transparent hover:text-darkPrimary text-primary text-[12px] lg:text-[18px] lg:font-[400] rounded transition-all duration-200 font-nunito  ${pysm} ${pxsm} ${px} ${py} `;
  return (
    <button onClick={onClick} className={css}>
      {children}
    </button>
  );
};

export default TextButton;
