import Banner from "./banner";
import "./Homepage.css";
import AboutUsCard from "./about-us-card";
import BenefitsCard from "./benefits-card";
import DiscoverCard from "./discover-card";
import FirstContributors from "./first-contributors";
import SecondContributors from "./second-contributors";
import ProfileCard from "./profile-card";
import ContributorCard from "./contributors-card";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { getAllUsers } from "../../store/action/user-action";
import { Helmet } from "react-helmet";

const Homepage = () => {
  const dispatch = useDispatch();
  const { allUsers } = useSelector((state) => state.userDetails);

  useEffect(() => {
    if (!allUsers?.length) {
      dispatch(getAllUsers());
    }
  }, []);

  return (
    <div className=" overflow-hidden">
      <Helmet>
        <meta charSet="utf-8" />

        <title> Project Buddy</title>

        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Banner />
      <AboutUsCard />
      <BenefitsCard />
      <DiscoverCard />
      <FirstContributors />
      <SecondContributors />
      <ProfileCard />
      <ContributorCard />
    </div>
  );
};
export default Homepage;
