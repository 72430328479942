export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const USER_LOGOUT = "USER_LOGOUT";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_USER_DATA = "GET_USER_DATA";
export const SEARCH_USER = "SEARCH_USER";
export const CLEAR_USER_DATA = "CLEAR_USER_DATA";
export const CLEAR_SEARCH_DATA = "CLEAR_SEARCH_DATA";
export const GET_GRAPH_DATA = "GET_GRAPH_DATA";
export const CLEAR_GRAPH_DATA = "CLEAR_GRAPH_DATA";
export const GRAPH_STATUS = "GRAPH_STATUS";
export const USER_STATUS = "USER_STATUS";
