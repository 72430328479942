import React,  { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import FeatureForm from "./feature-form";
import BugForm from "./bug-form";

function Contactus({ setIsOpen }) {
  // const [active, setActive] = useState(
  //   "inline-block p-4 px-8 text-primary border-b-2 border-[#03a2b7] rounded-t-lg active"
  // );
  // const [inActive, setInActive] = useState(
  //   "inline-block p-4 px-8 border-b-2 border-transparent rounded-t-lg hover:text-gray-300 hover:border-gray-300 "
  // );
  // const [isActive, setIsActive] = useState(true);

  return (
    <div className="bg-[#232C36] px-5 w-[90%] lg:w-[30rem] lg:h-80v overflow-x-hidden	 overflow-scroll  lg:px-8 z-99 relative pb-5 lg:pb-8 pt-5 lg:pt-8 rounded-xl m-auto overlay">
      <button
        className="absolute top-[10px] right-[1rem] lg:top-[13px] lg:right-[1rem] bg-secondary hover:bg-[#7bc585] rounded-full text-white p-[1px] lg:p-[2px] "
        onClick={() => setIsOpen(false)}
      >
        <RxCross2 className="lg:w-[1rem] lg:h-[1rem]" />
      </button>
      <p className="text-white font-mona_sans_thin  leading-[20px] text-[18px] lg:leading-[30px] lg:text-[25px] mt-4 md:mt-0">
        Terms & Privacy Policy
      </p>

      {/* <div className="text-sm font-medium text-center text-gray-500  ">
        <ul className="flex flex-wrap -mb-px">
          <li className=" w-1/2">
            <a
              onClick={() => setIsActive(true)}
              href="#"
              className={isActive ? active : inActive}
            >
              Feature Report
            </a>
          </li>
          <li className=" w-1/2">
            <a
              onClick={() => setIsActive(false)}
              href="#"
              className={isActive ? inActive : active}
            >
              Bug Report
            </a>
          </li>
        </ul>
      </div> */}
      <div>
        {" "}
        <FeatureForm />{" "}
      </div>
    </div>
  );
}

export default Contactus;
