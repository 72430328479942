import React from 'react'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import OfficialIcon from "../static/img/official.png";
import "../font.css";
const Contributors = ({ data }) => {
  const { avatar, name, bio, login, visited } = data || {};
  const navigate = useNavigate();
  return (
    <div className="flex justify-around h-[14.35rem]  p-4  border shadow-[0px_0px_5px_0px_#ffffff29] hover:shadow-[0px_0px_18px_7px_#ffffff29] rounded-xl border-solid border-[#070707] bg-backGroundCard ">
      <>
        {data ? (
          <div className="rounded-full	border-2 border-white w-[55px] h-fit flex justify-center items-center overflow-hidden mr-2">
            <img src={avatar} className="w-[50px] rounded-full" />
          </div>
        ) : (
          <SkeletonTheme
            baseColor="#202020"
            highlightColor="#444"
            height="3.5rem"
          >
            <p className=" w-[55px]">
              <Skeleton circle={true} />
            </p>
          </SkeletonTheme>
        )}

        <div className="grid grid-cols-1 divide-y p-2 w-[12rem]">
          <div>
            <p className="font-nunito flex items-center text-[15px] font-bold text-white leading-[25px]">
              {data ? (
                <>
                  {["amirSohel007"].includes(login) && (
                    <img
                      className="mr-2"
                      style={{ width: 15, height: 15 }}
                      src={OfficialIcon}
                    />
                  )}
                  {name || login}
                </>
              ) : (
                <SkeletonTheme baseColor="#202020" highlightColor="#444">
                  <Skeleton className="w-[100px]" />
                </SkeletonTheme>
              )}
            </p>
            <p className="font-nunito h-[4.65rem]  text-[13px] font-bold text-grey leading-[25px] ellipsis">
              {data ? (
                bio || 'Bio needs to update yet on Github'
              ) : (
                <SkeletonTheme baseColor="#202020" highlightColor="#444">
                  <Skeleton className="w-full" count={3} />
                </SkeletonTheme>
              )}
            </p>
          </div>
          <div>
            <div className="flex">
              <img src="Images/star.svg" />
              <p className="font-nunito  text-[13px] font-bold text-grey leading-[30px] ml-2">
                {visited || 0} times seen
              </p>
            </div>
            <div className=" float-right ">
              {login ? (
                <button
                  className="bg-transparent hover:text-darkPrimary text-primary text-[12px] lg:text-[14px] lg:font-[400] rounded transition-all duration-200 font-mona_sans"
                  onClick={() => navigate(`/view-profile/${login}`)}
                >
                  View Profile
                </button>
              ) : (
                <SkeletonTheme baseColor="#202020" highlightColor="#444">
                  <Skeleton className="w-[100px]" />
                </SkeletonTheme>
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Contributors;
