import { useSelector } from "react-redux";
import Contributors from "../../core/contributors";
import "../homepage/Homepage.css";
import { useDispatch } from "react-redux";
import  React, { useEffect } from "react";
import { getAllUsers } from "../../store/action/user-action";
import SearchBar from "../../core/searchBar";
import { Helmet } from "react-helmet";
import "./find-mates.css";

const FindMate = () => {
  const dispatch = useDispatch();
  const {
    userInfo: { response },
    allUsers,
  } = useSelector((state) => state.userDetails);
  const { login } = response || {};

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);
  return (
    <div className="background ">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Find Contributors</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div className=" px-4 pt-16 lg:pt-28 pb-8 lg:pb-16 lg:px-24 ">
        <div className="bg-[#12181F] px-5 lg:px-10 lg:pb-10 pt-5 lg:pt-10 rounded-xl ">
          <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center">
            <p className="text-white font-mona_sans_thin  leading-[40px] text-[18px] lg:leading-[48px] lg:text-[30px] mb-2 lg:mb-0">
              Finds Mates
            </p>
            <div className="md:w-1/2">
              <SearchBar />
            </div>
          </div>

          <p className="text-grey font-nunito  text-[12px] leading-[20px] lg:text-[17px] lg:leading-[30px] py-2 md:py-0 mt-2 mb-2 lg:mt-4 lg:mb-8">
          Discover the impactful work of our contributors, driving innovation and progress in the tech world.
          </p>
          <div className=" grid grid-col-1 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-col5 gap-4 lg:gap-6 mb-3 lg:mb-4 py-2 pr-2 scroll">
            {!allUsers && <Contributors />}
            {allUsers?.map((item) => {
              return <Contributors data={item} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindMate;
