import { FEEDBACK_ADDED, GET_FEEDBACK } from "../constant/post";

const initialState = {
    feedbackList: [],
    feedbackAdded:false
};

const FeedbackReducer = (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case GET_FEEDBACK:
            return {
                ...state,
                feedbackList: action.payload,
            };
        case FEEDBACK_ADDED:
            return{
                ...state,
                feedbackAdded:action.payload
            }
        default:
            return state;
    }
};

export default FeedbackReducer