import DiscriptionCardWithSIdeImage from "./discriptions/discriptionCardWithSIdeImage";
import { motion, useInView } from "framer-motion";
import React, { useRef, useState, useEffect } from "react";

import "./Homepage.css";
import notifications from "../notifications";
import { useSelector } from "react-redux";
const AboutUsCard = () => {
  const { userInfo } = useSelector((state) => state.userDetails);
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [isMobile] = useState(() => window.innerWidth < 700);
  const [height, setHeight] = useState("");

  const signup = () => {
    if (userInfo?.response?.login) {
      notifications("error", "You are already SignedUp");
    } else {
      const login = document.getElementById("login");
      login.childNodes[1].click();
      window.scrollTo({ top: 0, left: 0 });
    }
  };

  useEffect(() => {
    if (isMobile) {
      setHeight("200px");
    } else {
      setHeight("263px");
    }
  }, [isMobile]);
  return (
    <div className=" px-8 pt-12 lg:pt-20 lg:px-24">
      <div className="flex h-[200px] lg:h-[300px] ">
        <div ref={ref} className="relative">
          {isInView && (
            <motion.div
              initial={{ height: "0px" }}
              animate={{ height: height }}
              exit={{ height: "0px" }}
              transition={{
                duration: "1.5",
              }}
              className=" h-[200px] lg:h-[300px] aboutUsBackground w-[5px] lg:w-[8px] mt-8 lg:mt-10 mr-3 lg:mr-5"
            ></motion.div>
          )}
          <img
            data-aos="fade-down"
            src="Images/circle.svg"
            className="text-white absolute  w-3 lg:w-5 left-[-3px] lg:left-[-5px] top-2"
          />
        </div>

        <div>
          <p
            data-aos="fade-left"
            data-aos-duration="4000"
            className="text-secondary font-nunito  text-[18px] lg:text-[30px] lg:leading-[30px]"
          >
            About Us
          </p>
          <h3
            data-aos="fade-left"
            data-aos-duration="6000"
            className="text-white font-lato font-bold   text-[25px] leading-[30px] lg:leading-[50px] lg:text-[40px] lg:w-9/12 mt-4 "
          >
            Your Trusted Platform for Finding Project Partners, Analyzing your
            Profile, & To boost Your Job opportunities.
          </h3>
          <p
            data-aos="fade-left"
            data-aos-duration="7000"
            className="text-grey text-[12px] lg:text-[15px] font-nunito  leading-[20px] lg:leading-[30px] lg:w-6/12 mt-8"
          >
            Find, Analys & Enhance the code to success
          </p>
        </div>
      </div>

      <DiscriptionCardWithSIdeImage
        buttonName="Sign Up Now"
        onClick={signup}
        imageName="Images/aboutSectionPNG.png"
        description={
          "Connect Your GitHub Account to Easily Fetch Data. Add Projects, Discover New Ones, Expand Your Audience, Analyze Your Profile, and Network with Like-Minded Individuals."
        }
      />
    </div>
  );
};

export default AboutUsCard;
