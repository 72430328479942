import TextButton from "../../core/text-button";
import { FaArrowRightLong } from "react-icons/fa6";

import "./Homepage.css";
import ExploreProjectCard from "../../core/explore-project-card";
import { motion, useInView } from "framer-motion";
import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllProjects } from "../../store/action/post-action";
import { useNavigate } from "react-router-dom";

const AboutUsCard = () => {
  const dispatch = useDispatch();
  const { allProjects, tech } = useSelector((state) => state.postDetails);

  useEffect(() => {
    if (!allProjects.length) {
      dispatch(getAllProjects({ tech }));
    }
  }, [tech]);

  const getRandomPost =
    allProjects[Math.floor(Math.random() * allProjects?.length)];

  const ref = useRef(null);
  const isInView = useInView(ref);
  const [isMobile] = useState(() => window.innerWidth < 700);
  const [height, setHeight] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (isMobile) {
      setHeight("200px");
    } else {
      setHeight("965px");
    }
  }, [isMobile]);
  return (
    <div className=" mt-[29rem] px-8 pt-8 lg:pt-0 lg:mt-0 lg:px-24">
      <div className="flex h-[200px] lg:h-[965px]">
        <div ref={ref} className="relative ">
          {isInView && (
            <motion.div
              initial={{ height: "0px" }}
              animate={{ height: height }}
              exit={{ height: "0px" }}
              transition={{
                duration: "1.5",
              }}
              className=" h-[200px] lg:h-[965px] discoverBackground w-[5px] lg:w-[8px] mt-8 lg:mt-0 mr-3 lg:mr-5"
            ></motion.div>
          )}
          <img
            data-aos="fade-down"
            src="Images/circle.svg"
            className="text-white absolute  w-3 lg:w-5 left-[-3px] lg:left-[-5px] top-2 lg:top-[39.25rem]"
          />
        </div>

        <div className="w-full lg:mt-[39rem] relative">
          <img
            src="Images/discoverSectionSVG.svg"
            className="absolute right-[-27px] lg:right-[-92px] w-[9rem] lg:w-[23rem]"
          />
          <p
            data-aos="fade-left"
            data-aos-duration="4000"
            className="text-secondary font-nunito  text-[18px] lg:text-[30px] lg:leading-[30px]  "
          >
            Discover
          </p>
          <h3
            data-aos="fade-left"
            data-aos-duration="6000"
            className="text-white font-lato font-bold   text-[25px] leading-[30px] lg:leading-[50px] lg:text-[40px] lg:w-6/12 mt-4 "
          >
            Discover Relevant Projects
          </h3>
          <p
            data-aos="fade-left"
            data-aos-duration="8000"
            className="text-grey text-[12px] lg:text-[15px] font-nunito  leading-[20px] lg:leading-[30px] lg:w-5/12 mt-8"
          >
            Collaborate with like-minded contributors by exploring projects posted by registered users.
          </p>
          <div className=" mt-6 lg:mt-12 lg:mb-20 ">
            <TextButton onClick={() => navigate(`/exploreProject`)}>
              <div className="flex items-center">
                Explore Project &nbsp;&nbsp;
                <FaArrowRightLong className="text-white" />
              </div>
            </TextButton>
          </div>
        </div>
      </div>
      <div
        className="lg:w-9/12 lg:ml-[-1.75rem]"
        data-aos="fade-right"
        data-aos-easing="ease-out-sine"
      >
        <ExploreProjectCard
          data={getRandomPost}
          noCross={true}
          nodelete={true}
        />
      </div>
    </div>
  );
};

export default AboutUsCard;
