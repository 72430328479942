import React from "react";
import GitHubLogin from "react-github-login";
import { NavLink } from "react-router-dom";
import { AiOutlineAlignLeft } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import FillButton from "../../core/fill-button";

const DeviceNavbar = ({
  isOpen,
  setIsOpen,
  response,
  handleSignOut,
  openAddProject,
  onSuccess,
}) => {
  return (
    <div className="flex justify-between lg:hidden">
      {!response && (
        <div className="relative github_wrapper basis-18">
          <img
            style={{ width: 24 }}
            className="w-8 absolute top-[9px] lg:top-[13px] left-[9px]"
            src="https://static-00.iconduck.com/assets.00/github-icon-2048x2048-dpporae2.png"
          />
          <GitHubLogin
            className="github_btn"
            clientId={process.env.REACT_APP_CLIENT_ID}
            onSuccess={onSuccess}
            redirectUri=""
          />
        </div>
      )}

      {response && (
        <div className="flex items-center mb-1 lg:mb-0 lg:ml-2">
          <div className="rounded-full	border-2 border-white w-[29px] h-fit flex justify-center items-center overflow-hidden mr-2 ml-8">
            <img
              src={response.avatar}
              className="w-[25px] h-[25px] rounded-full"
            />
          </div>
          <p className="font-nunito  text-[12px] lg:text-[15px] font-bold text-white leading-[30px]">
            {response.name}
          </p>
        </div>
      )}

      <div className="flex justify-end items-center lg:hidden">
        <button
          className="font-nunito  text-white z-9 text-[24px] font-bold leading-[30px]  items-center mr-3 hover:text-white"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? <RxCross2 /> : <AiOutlineAlignLeft />}
        </button>
      </div>
      {isOpen && (
        <div className="bg-backGroundCard p-4 flex flex-col fixed inset-y-0 w-100 h-full inset-x-0 items-center justify-center">
          <NavLink
            to="/"
            onClick={() => setIsOpen(false)}
            className="bg-transparent hover:text-darkPrimary text-white lg:text-[18px] lg:font-[400] rounded transition-all duration-200 font-nunito  py-1 px-1 lg:py-2 lg:px-7 text-[18px] mb-4 "
          >
            Home
          </NavLink>
          <NavLink
            to="/explore-projects"
            onClick={() => setIsOpen(false)}
            className="bg-transparent hover:text-darkPrimary text-white text-[18px] mb-4  lg:text-[18px] lg:font-[400] rounded transition-all duration-200 font-nunito  py-1 px-1 lg:py-2 lg:px-7"
          >
            Explore Projects
          </NavLink>
          <NavLink
            to="/find-mate"
            onClick={() => setIsOpen(false)}
            className="bg-transparent hover:text-darkPrimary text-white text-[18px] mb-4  lg:text-[18px] lg:font-[400] rounded transition-all duration-200 font-nunito   py-1 px-1 lg:py-2 lg:px-7"
          >
            Finds Mates
          </NavLink>
          <NavLink
            className="bg-transparent hover:text-darkPrimary text-white text-[18px] mb-4  lg:text-[18px] lg:font-[400] rounded transition-all duration-200 font-nunito   py-1 px-1 lg:py-2 lg:px-7"
            to="/reviews"
            onClick={() => setIsOpen(false)}
          >
            Ask / Query
          </NavLink>
          {response && (
            <>
              <FillButton
                pysm="py-1"
                pxsm="px-4"
                py="lg:py-2"
                px="lg:px-7"
                onClick={openAddProject}
              >
                Add Project
              </FillButton>
              <div className="mt-2">
                <FillButton
                  pysm="py-1"
                  pxsm="px-4"
                  py="lg:py-2"
                  px="lg:px-7"
                  onClick={handleSignOut}
                >
                  Logout
                </FillButton>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default DeviceNavbar;
