import React from 'react'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ProfileCard from "./profile";

const ProjectsCards = ({ user_profile }) => {
  return (
    <>
      <div className="pl-2 lg:pl-8">
        <p className="text-white font-mona_sans_thin  leading-[20px] text-[16px] lg:leading-[25px] lg:text-[25px]">
          {user_profile?.getRepos?.length > 0
            ? "My Awesome projects"
            : "No public projects"}
        </p>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 gap-4 lg:gap-4  p-2 lg:p-8 ">
        {user_profile ? (
          user_profile?.getRepos?.map((item, index) => {
            return <ProfileCard data={item} />;
          })
        ) : (
          <SkeletonTheme baseColor="#202020" highlightColor="#444">
            <Skeleton className="h-[10rem]" />
          </SkeletonTheme>
        )}
      </div>
    </>
  );
};

export default ProjectsCards;
