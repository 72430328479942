import toast from "react-hot-toast";

const notifications = (type, message) => {
  toast[type](message, {
    duration: 2000,
    position: "bottom-center",
    // Change colors of success/error/loading icon
    iconTheme: {
      secondary: "#fff",
    },
    // Aria
    ariaProps: {
      role: "status",
      "aria-live": "polite",
    },
  });
};


export default notifications;
