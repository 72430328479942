import toast from "react-hot-toast";
import notifications from "../../components/notifications";
import dataProvider from "../../service";
import {
  DELETE_POST,
  GET_ALL_PROJECTS,
  GET_RESPONCE,
  POST_CREATED,
} from "../constant/post";
import { createHash } from "../../utils";

export const createProject = (payload, navigation) => async (dispatch) => {
  try {
    const data = dataProvider.post("post/create_post", payload, {
      headers:{
        'Authorization': createHash(payload.user),
      }
    });
    toast.promise(data, {
      loading: "Adding Project ....",
      success: () => {
        notifications("success", "Project successfully added.");
        dispatch(getAllProjects([]));
        navigation("/explore-projects");
        dispatch({
          type: POST_CREATED,
          payload: true,
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export const deleteProject = (payload) => async (dispatch) => {
  try {
    const data = dataProvider.get(`post/delete?id=${payload}`);
    toast.promise(data, {
      loading: "Deleting Project ....",
      success: () => {
        notifications("success", "Project successfully deleted.");
        dispatch(getAllProjects([]));
        dispatch({
          type: DELETE_POST,
          payload: true,
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export const getAllProjects = ({tech=[], myFeed, id}) => async (dispatch) => {
  try {
    dispatch({
      type: GET_RESPONCE,
      payload: false,
    });
    let response;
    if(myFeed){  // for only my posts
      response = await dataProvider.post("post/get_post", {
        tech:tech,
        id:id
      });
    } else {
      response = await dataProvider.post("post/get_post", {tech:tech});
    }
    const data = response.data;
    if (response.status === 200) {
      dispatch({
        type: GET_RESPONCE,
        payload: true,
      });
    }
    if (data?.length >= 1) {
      dispatch({
        type: GET_ALL_PROJECTS,
        payload: data,
      });
    }
  } catch (error) {
    console.error(error);
  }
};
