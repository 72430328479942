import { createStore, combineReducers, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import { thunk } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import userDetailsReducer from "./reducer/user-reducer";
import postDetailsReducer from "./reducer/post-reducer";
import { IsDevelopment } from "../config";
import FeedbackReducer from "./reducer/feedback-reducer";

const reducer = combineReducers({
  userDetails: userDetailsReducer,
  postDetails: postDetailsReducer,
  feedbackDetails: FeedbackReducer
});

const logger = createLogger();
let middleware = [];
if (process.env.NODE_ENV === "development") {
  middleware = [thunk, logger];
} else {
  middleware = [thunk];
}

const store = createStore(
  reducer,
  IsDevelopment ? composeWithDevTools(applyMiddleware(...middleware)) : applyMiddleware(...middleware)
);

export default store;
