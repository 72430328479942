export const GET_ALL_PROJECTS = "GET_ALL_PROJECTS";
export const POST_CREATED = "POST_CREATED";
export const AFTER_POST_CREATED = "AFTER_POST_CREATED";
export const DELETE_POST = "DELETE_POST";
export const ADD_TECH = "ADD_TECH";
export const CLEAR_TECH = "CLEAR_TECH";
export const CLEAR_PROJECT = "CLEAR_PROJECT";
export const GET_RESPONCE = "GET_RESPONCE";
export const ONLY_MY_FEED = "ONLY_MY_FEED"
export const ADD_FEEDBACK = "ADD_FEEDBACK"
export const GET_FEEDBACK = "GET_FEEDBACK"
export const FEEDBACK_ADDED = "FEEDBACK_ADDED"