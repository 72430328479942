import React from 'react'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const ProfileCard = ({ data }) => {
  const { display, start, totalCommit, fork, main_language, repo_url } = data || {};

  return (
    <div className="  p-4  border shadow-[0px_0px_5px_0px_#ffffff29] hover:shadow-[0px_0px_18px_7px_#ffffff29] rounded-xl border-solid border-[#070707] bg-backGroundCard overflow-hidden">
      <a
        href={repo_url}
        target="_blank"
        className="font-nunito block text-center cursor-pointer text-[15px] font-bold text-[#faba6fcc] hover:text-[#ffad4d] leading-[25px]"
      >
        {display ? (
          display
        ) : (
          <SkeletonTheme baseColor="#202020" highlightColor="#444">
            <Skeleton className="w-[100px]" />
          </SkeletonTheme>
        )}
      </a>
      <div className="flex justify-around my-4">
        <div className="flex flex-col items-center ">
          <p className="font-nunito text-primary text-[15px] font-bold  leading-[25px]">
            {fork != undefined ? (
              fork
            ) : (
              <SkeletonTheme baseColor="#202020" highlightColor="#444">
                <Skeleton className="w-[100px]" />
              </SkeletonTheme>
            )}
          </p>
          <p className="font-nunito text-grey  text-[15px] font-bold  leading-[25px]">
            {fork != undefined ? (
              "Fork"
            ) : (
              <SkeletonTheme baseColor="#202020" highlightColor="#444">
                <Skeleton className="w-[100px]" />
              </SkeletonTheme>
            )}
          </p>
        </div>
        <div className="flex flex-col items-center ">
          <p className="font-nunito text-primary text-[15px] font-bold leading-[25px]">
            {start != undefined ? (
              start
            ) : (
              <SkeletonTheme baseColor="#202020" highlightColor="#444">
                <Skeleton className="w-[100px]" />
              </SkeletonTheme>
            )}
          </p>
          <p className="font-nunito text-grey text-[15px] font-bold leading-[25px]">
            {start != undefined ? (
              "Stars"
            ) : (
              <SkeletonTheme baseColor="#202020" highlightColor="#444">
                <Skeleton className="w-[100px]" />
              </SkeletonTheme>
            )}
          </p>
        </div>
        <div className="flex flex-col items-center ">
          <p className="font-nunito text-primary text-[15px] font-bold  leading-[25px]">
            {totalCommit != undefined ? (
              totalCommit
            ) : (
              <SkeletonTheme baseColor="#202020" highlightColor="#444">
                <Skeleton className="w-[100px]" />
              </SkeletonTheme>
            )}
          </p>
          <p className="font-nunito text-grey  text-[15px] font-bold  leading-[25px]">
            {totalCommit != undefined ? (
              "Commits"
            ) : (
              <SkeletonTheme baseColor="#202020" highlightColor="#444">
                <Skeleton className="w-[100px]" />
              </SkeletonTheme>
            )}
          </p>
        </div>
      </div>
      {main_language != undefined ? (
        <p className="font-nunito text-center  text-[15px] font-bold text-white leading-[25px]">
          The main language is{" "}
          <span className="text-primary font-bold">{main_language}</span>
        </p>
      ) : (
        <p className="font-nunito text-center text-[15px] font-bold text-white leading-[25px]">
          The main language is{" "}
          <span className="text-primary font-bold">Others</span>
        </p>
      )}
    </div>
  );
};

export default ProfileCard;
