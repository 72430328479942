import React, { useState } from "react";
import FillButton from "../../core/fill-button";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import Tags from "../../core/tags";
import { createProject } from "../../store/action/post-action";
import notifications from "../../components/notifications";
import { useNavigate } from "react-router-dom";

const AddProject = ({ background = "#12181F", onClose, show = true }) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userDetails);
  const login = userInfo?.response?.login || "";
  const [data, setData] = useState({
    title: "",
    description: "",
    tech: [],
    user: login,
    url: "",
  });

  const [tags, setTags] = useState("");

  const handleClick = () => {
    let stack = data.tech;
    let findValue = stack.find((i) => i === tags);
    if (findValue || tags === "") {
      setTags("");
      return;
    }
    stack.unshift(tags);
    setTags("");
    setData(() => ({ ...data, tech: [...stack] }));
  };
  const handleRemove = (index) => {
    console.log("enter", index);
    let stack = data.tech;
    stack.splice(index, 1);
    setData(() => ({ ...data, tech: [...stack] }));
  };

  const handleAddProject = () => {
    if (
      Object.keys(data).every((item) => data[item] && data?.tech.length > 0)
    ) {
      const regex = /^https:\/\/github\.com\/[a-zA-Z0-9_-]+\/[a-zA-Z0-9_-]+(?:\/[a-zA-Z0-9_-]+)*$/
      // check for github repo validation
      if(regex.test(data.url)){
        dispatch(createProject(data, navigation));
      } else {
        notifications("error", "Please enter valid Github Repository link");
        return;
      }

    } else {
      notifications("error", "All fields are mandatory.");
      return;
    }
  };
  const onHandleEnter = (e) => {
    if (e.key === "Enter") {
      handleClick();
    }
  };

  let clas = `bg-[${background}] px-5  overflow-x-hidden	 h-70v md:h-60 lg:h-[32rem] overflow-scroll  lg:px-8 z-99 relative lg:pb-8 pt-5 lg:pt-8 rounded-xl mx-4  lg:mx-24 overlay`;
  if (!show) {
    clas = `bg-[${background}] px-5   h-[30rem] md:h-50 lg:h-[34rem] lg:px-8 z-99 relative lg:pb-8 pt-5 lg:pt-8 rounded-xl mx-4  lg:mx-20`;
  }
  return (
    <div className={`${clas} `}>
      {show && (
        <button
          className="absolute top-[10px] right-[1rem] lg:top-[13px] lg:right-[1rem] bg-secondary hover:bg-[#7bc585] rounded-full text-white p-[1px] lg:p-[2px] "
          onClick={onClose}
        >
          <RxCross2 className="lg:w-[1.5rem] lg:h-[1.5rem]" />
        </button>
      )}
      <p className="text-white font-mona_sans_thin  leading-[30px] text-[20px] lg:leading-[48px] lg:text-[30px]">
        Add project
      </p>
      <p className="text-grey font-nunito  text-[12px] leading-[20px] lg:text-[17px] lg:leading-[30px] mt-2 mb-2 lg:mt-4 lg:mb-8">
        Fill in the details below to add a new project
      </p>
      <div className="flex flex-col lg:flex-row lg:justify-between">
        <div className="w-full lg:w-[45%] flex flex-col ">
          <label className="text-primary font-nunito  text-[12px] leading-[20px] lg:text-[17px] lg:leading-[30px] ">
            Project Name*
          </label>
          <div className="px-[1px] lg:px-[2px] py-[1px] lg:py-[2px]  bg-gradient-to-r from-emerald-300 via-[#70C1CC] to-emerald-300  text-emerald-300 rounded-lg">
            <input
              className="block w-full bg-gray-800 px-2 lg:px-4 py-1 lg:py-2 ps-4 lg:ps-6 text-[12px] lg:text-[18px]  rounded-lg  outline-none"
              placeholder="Enter your project name"
              onChange={(e) =>
                setData(() => ({ ...data, title: e.target.value }))
              }
            />
          </div>
        </div>
        <div className="w-full lg:w-[45%] flex flex-col mt-3 lg:mt-0 ">
          <label className="text-primary font-nunito  text-[12px] leading-[20px] lg:text-[17px] lg:leading-[30px] ">
            Repository URL
          </label>
          <div className="px-[1px] lg:px-[2px] py-[1px] lg:py-[2px]  bg-gradient-to-r from-emerald-300 via-[#70C1CC] to-emerald-300  text-emerald-300 rounded-lg">
            <input
              className="block w-full bg-gray-800 px-2 lg:px-4 py-1 lg:py-2 ps-4 lg:ps-6 text-[12px] lg:text-[18px]  rounded-lg  outline-none"
              placeholder="Enter your github repository URL"
              onChange={(e) =>
                setData(() => ({ ...data, url: e.target.value }))
              }
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row lg:justify-between mt-3 lg:mt-10">
        <div className="w-full lg:w-[45%] flex flex-col ">
          <label className="text-primary font-nunito  text-[12px] leading-[20px] lg:text-[17px] lg:leading-[30px]  ">
            Project Description*
          </label>
          <div className="px-[1px] lg:px-[2px] py-[1px] lg:py-[2px]  bg-gradient-to-r from-emerald-300 via-[#70C1CC] to-emerald-300  text-emerald-300 rounded-lg">
            <textarea
              rows={4}
              className="block w-full bg-gray-800 px-2 lg:px-4 py-1 lg:py-2 ps-4 lg:ps-6 text-[12px] lg:text-[18px]  rounded-lg  outline-none"
              placeholder="Enter description"
              onChange={(e) =>
                setData(() => ({ ...data, description: e.target.value }))
              }
            />
          </div>
        </div>
        <div className="w-full lg:w-[45%] flex flex-col   relative">
          <label className="text-primary font-nunito  text-[12px] leading-[20px] lg:text-[17px] lg:leading-[30px] mt-3 lg:mt-0 ">
            Tags
          </label>
          <div className="px-[1px] lg:px-[2px] py-[1px] lg:py-[2px]  bg-gradient-to-r from-emerald-300 via-[#70C1CC] to-emerald-300  text-emerald-300 rounded-lg relative">
            <input
              value={tags}
              onChange={(e) => setTags(e.target.value.trim())}
              className="block w-full bg-gray-800 px-2 lg:px-4 py-1 lg:py-2 ps-4 lg:ps-6 text-[12px] lg:text-[18px]  rounded-lg  outline-none"
              placeholder="Enter suitable tags for your project (5 max)"
              onKeyDown={onHandleEnter}
            />
            {show && (
              <button
                className="text-white font-bold text-[15px] lg:text-[25px] border-2 rounded-full w-[25px] lg:w-[40px]  absolute right-[9px] top-[2px] lg:top-[4px] lg:right-[12px] "
                onClick={handleClick}
              >
                +
              </button>
            )}
          </div>

          <div className="flex flex-wrap mt-2 lg:mt-4">
            {data.tech.map((i, index) => (
              <Tags
                key={index}
                name={i}
                handleRemove={handleRemove}
                index={index}
              />
            ))}
          </div>
          <div className="mt-4 lg:mt-8 flex justify-end">
            <button
              onClick={handleAddProject}
              className="bg-[#70C1CC] hover:bg-[#03a2b7] text-white text-[12px] lg:text-[16px] lg:font-[400]  transition-all duration-200 font-nunito px-4 py-2 rounded-lg mt-4"
            >
              Submit Now
            </button>
            {/* <FillButton
              onClick={handleAddProject}
              pysm="py-1"
              pxsm="px-4"
              py="lg:py-2"
              px="lg:px-7"
            >
              Submit Now
            </FillButton> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProject;
