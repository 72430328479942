import React from "react";
import { exportComponentAsJPEG } from "react-component-export-image";
import { MdOutlineFileDownload } from "react-icons/md";

const DownloadReport = ({ componentRef }) => {
  return (
    <button
      className="bg-[#ace2ea] hover:bg-darkPrimary border border-[#368893] text-[#368893] text-[12px] md:text-[16px] hover:text-white transition-all duration-500 md:translate-x-[131px] hover:translate-x-0 export_image h-[40px] md:h-[50px] p-2 rounded-xl w-[120px] md:w-[160px] z-0"
      onClick={() => exportComponentAsJPEG(componentRef)}
    >
      <MdOutlineFileDownload /> Export Report
    </button>
  );
};

export default DownloadReport;
