import React, { useEffect, useState } from "react";
import ExploreProjectCard from "../../core/explore-project-card";
import "../homepage/Homepage.css";
import Languages from "./languages";
import { useDispatch, useSelector } from "react-redux";
import { getAllProjects } from "../../store/action/post-action";
import { Helmet } from "react-helmet";
import { IoFilterSharp } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { lang } from "./data";
import {
  CLEAR_PROJECT,
  CLEAR_TECH,
  ONLY_MY_FEED,
} from "../../store/constant/post";
import Filters from "./filters";

const ExploreProject = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [ischecked, setIschecked] = useState(false);
  const dispatch = useDispatch();
  const { allProjects, tech, allProjectsResponce, myFeed } = useSelector(
    (state) => state.postDetails
  );
  const {
    userInfo: { response },
  } = useSelector((state) => state.userDetails);
  const { _id: id } = response || {};
  const handleClear = () => {
    dispatch({
      type: CLEAR_TECH,
    });
    dispatch({
      type: ONLY_MY_FEED,
      payload: false,
    });
    setIschecked(false);
  };

  useEffect(() => {
    dispatch({
      type: CLEAR_PROJECT,
    });
    // every time it checks if myFeed flag is true then it find post under my feeds
    dispatch(getAllProjects({ tech, id, myFeed }));
  }, [tech]);

  const handleOnCheck = (value) => {
    setIschecked(value);
    if (value) {
      dispatch({
        type: ONLY_MY_FEED,
        payload: true,
      });
      dispatch(getAllProjects({ tech, id, myFeed: true }));
    } else {
      dispatch({
        type: ONLY_MY_FEED,
        payload: false,
      });
      dispatch(getAllProjects({ tech, id, myFeed: false }));
    }
  };

  return (
    <div className="background ">
      <Helmet>
        <meta charSet="utf-8" />
        <title> Discover Projects</title>

        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div className=" px-4 pt-16 lg:pt-28 pb-8 lg:pb-16 lg:px-24 ">
        <div className="bg-[#12181F] px-5 lg:px-8 lg:pb-8 pt-5 lg:pt-8 rounded-xl flex">
          <div className="hidden lg:block w-3/12">
            <Filters
              handleClear={handleClear}
              handleOnCheck={handleOnCheck}
              response={response}
              ischecked={ischecked}
              lang={lang}
              Languages={Languages}
            />
          </div>

          <div className="w-full lg:w-9/12 lg:pl-8">
            <div className="flex justify-between md:items-center lg:block">
              <div className="flex flex-col md:flex-row justify-between md:items-center">
                <p className="text-white font-mona_sans_thin  leading-[30px] text-[18px] lg:leading-[48px] lg:text-[30px]">
                  Explore Projects
                </p>
                <p className="text-white font-mona_sans_thin  leading-[30px] text-[12px] lg:leading-[48px] lg:text-[15px]">
                  Total count{" "}
                  <span className="font-bold text-primary">
                    {allProjects.length}
                  </span>
                </p>
              </div>

              <button
                className="font-nunito h-fit  text-[24px] font-bold text-grey leading-[30px] block lg:hidden items-center mr-3 hover:text-white"
                onClick={() => setIsOpen(!isOpen)}
              >
                {isOpen ? <RxCross2 /> : <IoFilterSharp />}
              </button>
              {isOpen && (
                <div className="absolute z-9 bg-[#232C36] p-4  top-[8rem] right-[2rem] w-7/12">
                  {" "}
                  <Filters
                    handleClear={handleClear}
                    handleOnCheck={handleOnCheck}
                    response={response}
                    ischecked={ischecked}
                    lang={lang}
                    Languages={Languages}
                  />
                </div>
              )}
            </div>
            <p className="text-grey font-nunito  text-[12px] leading-[20px] lg:text-[17px] lg:leading-[25px] mt-2 mb-4 lg:mt-4 lg:mb-8">
              Discover open-source projects, connect with experienced
              maintainers, and collaborate with a community of passionate
              contributors. Join over 150 registered users who are already
              making a difference.
            </p>
            <div className="h-[33rem] overflow-y-scroll py-2 pr-2">
              {!allProjectsResponce && <ExploreProjectCard />}
              {allProjectsResponce && allProjects.length === 0 && (
                <div className="flex h-[28rem] justify-center items-center">
                  <p className="text-grey  font-bold font-nunito  text-[12px] leading-[20px] lg:text-[25px] lg:leading-[25px] mt-2 mb-4 lg:mt-4 lg:mb-8">
                    No Project Found
                  </p>
                </div>
              )}
              {allProjects?.map((item) => {
                return (
                  <ExploreProjectCard
                    data={item}
                    noCross={true}
                    nodelete={false}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreProject;
