const { graphql} = require("@octokit/graphql");
const { recentActivityQuery, getCommitsQuery } = require("./graphql");
const { Octokit } = require("octokit");

// module.exports.createToken_API_URL = (code) =>
//   `${accessTokenAPI}?client_id=${process.env.CLIENT_ID}&client_secret="${process.env.SECRET_ID} &code=${code}`;

export const getTotalCounts = (data, key) => {
  const getTotalStar = data
    .filter((item) => item[key])
    .map((item) => item.stargazers_count)
    .reduce((pre, current) => pre + current, 0);
  return getTotalStar;
};

export const repoBasedOnLanguage = (data) => {
  const language = data.map((item) => item.language);
  const newObj = {};
  let count = 1;
  for (const value of language) {
    if (newObj[value ?? "Others"]) {
      newObj[value ?? "Others"] = ++count;
    } else {
      newObj[value ?? "Others"] = count;
    }
  }
  const newArr = [];

  Object.entries(newObj).map(([key, val]) =>
    newArr.push({
      id: key,
      label: `${key} (${val} repo)`,
      value: val,
      size: val.toString(),
      color: "hsl(174, 70%, 50%)",
    })
  );
  return newArr;
};

export const langBasedOnBytes = (data) => {
  const result = data.map((item) => ({ lang: item.language, size: item.size }));
  const dataObj = result.map((item) => ({
    ...item,
    lang: item.lang ?? "Others",
  }));
  const mergedData = dataObj.reduce((acc, curr) => {
    const existingItem = acc.find((item) => item.lang === curr.lang);
    if (existingItem) {
      existingItem.size += curr.size;
    } else {
      acc.push({ lang: curr.lang, size: curr.size });
    }
    return acc;
  }, []);

  const finalObj = mergedData.map((item) => ({
    ...item,
    label: `${item.lang} (${item.size >= 1024
      ? `${(item.size / 1024).toFixed(2)} MB`
      : `${item.size} KB`
      })`,
    id: item.lang,
    value: item.size,
    color: "hsl(174, 70%, 50%)",
    size:
      item.size >= 1024
        ? `${(item.size / 1024).toFixed(2)} MB`
        : `${item.size} KB`,
  }));

  return finalObj;
};

// Server calling methods
export const getPublicRepos = async (data, access_token) => {
  try {
    const octokit = graphql.defaults({
      headers: {
        authorization: `token ${access_token}`,
      },
    });

    const commitPromises = data.map(async (repo) => {
      const query = getCommitsQuery(repo)
      const result = await octokit(query);
      const repository = result.repository;

      return {
        totalCommit: repository.defaultBranchRef.target.history.totalCount,
        repo_url: `https://github.com/${repo.owner.login}/${repo.name}`,
        display: repo.full_name,
        start: repository.stargazers.totalCount,
        fork: repository.forks.totalCount,
        label: `${repo.name}( ${repository.defaultBranchRef.target.history.totalCount} commits)`,
        id: repo.name,
        value: repository.defaultBranchRef.target.history.totalCount,
        size: `${repository.defaultBranchRef.target.history.totalCount}`,
        main_language: repository.languages.nodes.length > 0 ? repository.languages.nodes[0].name : null,
      };
    });

    const commitAnalysis = await Promise.all(commitPromises);
    const finalResult = commitAnalysis.sort((a, b) => b.totalCommit - a.totalCommit).slice(0, 8);
    return finalResult;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const  getRecentActivities = async (data, access_token) => {
  try {
    const octokit = graphql.defaults({
      headers: {
        authorization: `token ${access_token}`,
      },
    });

    const commitPromises = data.map(async (repo) => {
      const query = recentActivityQuery(repo)
      const result = await octokit(query);
      const commit = result.repository.defaultBranchRef.target.history.edges[0].node;
      if (commit) {
        const repoCommitMessage = commit.message;
        const date = new Date(commit.committedDate).toDateString();
        return { message: repoCommitMessage, date: date, repo: repo.name };
      }
    });
    const commitResults = await Promise.all(commitPromises);
    const filteredResults = commitResults.filter(commit => commit !== undefined);
    const sortedResults = filteredResults.sort((a, b) => new Date(b.date) - new Date(a.date));
    return sortedResults;
  } catch (error) {
    console.error("Error fetching commit messages:", error.message);
    throw error;
  }
};

const fetchAllCommits = async (repo, access_token) => {
  const octokit = new Octokit({ auth: access_token });
  let allCommits = [];
  let page = 1;
  let perPage = 10;
  let hasMoreCommits = true;

  while (hasMoreCommits) {
    const { data } = await octokit.rest.repos.listCommits({
      owner: repo.owner.login,
      repo: repo.name,
      per_page: perPage,
      page: page,
    });
    if (data.length === 0) {
      hasMoreCommits = false; // No more commits
    } else {
      allCommits = allCommits.concat(data);
      page++;
    }
  }
  return allCommits;
};


export const getCommitGraph = async (data, access_token) => {
  const allCommits = await Promise.all(data.map((repo) => fetchAllCommits(repo, access_token)));
  const yearObject = {};
  const graphDate = [];
  allCommits.flat(Infinity).forEach((commit) => {
    const date = new Date(commit?.commit?.committer?.date);
    const year = date?.getFullYear();
    const month = date?.toLocaleString('default', { month: 'short' });
    if (!yearObject[year]) {
      yearObject[year] = {};
    }
    if (!yearObject[year][month]) {
      yearObject[year][month] = 0;
    }
    yearObject[year][month]++;
  });

  Object.entries(yearObject).forEach(([year, months]) => {
    const newObj = { year };
    Object.entries(months).forEach(([month, count]) => {
      newObj[month] = count;
    });
    graphDate.push(newObj);
  });
  return graphDate;
 }