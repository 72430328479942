import FillButton from "../../core/fill-button";
import GitHubLogin from "react-github-login";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onSuccessLogin, onUserSignOut } from "../../store/action/user-action";
import { handleOverlay } from "../../utils";
import { NavLink } from "react-router-dom";
import AddProject from "../add-project";
import DeviceNavbar from "./device";
import Logo from "../../static/img/Logo.svg";
import { AFTER_POST_CREATED } from "../../store/constant/post";
import Dropdown from "./nav-dropdown";
const Navbar = () => {
  const dispatch = useDispatch();

  const onSuccess = async ({ code }) => {
    dispatch(onSuccessLogin(code));
    setIsOpen(false);
  };

  const {
    userInfo: { response },
  } = useSelector((state) => state.userDetails);

  const { postCreated } = useSelector((state) => state.postDetails);

  const [isOpen, setIsOpen] = useState(false);
  const [isDropOpen, setIsDropOpen] = useState(false);
  const [isAddProjectOpen, setIsAddProjectOpen] = useState(false);

  const handleSignOut = () => {
    dispatch(onUserSignOut());
    setIsOpen(false);
    setIsDropOpen(false);
  };

  useEffect(() => {
    if (postCreated) {
      setIsAddProjectOpen(false);
      handleOverlay("auto");
      dispatch({
        type: AFTER_POST_CREATED,
      });
    }
  }, [postCreated]);

  const onclose = () => {
    handleOverlay("auto");
    setIsAddProjectOpen(false);
  };

  const openAddProject = () => {
    handleOverlay("hidden");
    setIsOpen(false);
    setIsAddProjectOpen(true);
  };

  return (
    <>
      <div className="w-full py-4 px-5 lg:py-8 lg:px-10 absolute z-99 ">
        <div className="flex items-center justify-between  w-100">
          <div>
            <NavLink to="/" className="flex items-center">
              <img src={Logo} className="w-8 h-5 lg:w-10 lg:h-10" />
              <div className="flex  lg:px-3">
                <p className="text-white font-nunito  text-[12px] lg:text-[15px] ">
                  PROJECT
                </p>
                <p className="text-primary font-nunito  text-[12px] lg:text-[15px] ">
                  MATE
                </p>
              </div>
            </NavLink>
          </div>

          <div className="w-7/12 lg:w-[53rem] relative">
            {/* desktop navbar start */}
            <div className="hidden lg:flex justify-around">
              <NavLink
                className={({ isActive }) =>
                  `${
                    isActive
                      ? "text-darkPrimary  bg-transparent hover:text-darkPrimary  text-[12px] lg:text-[16px] lg:font-[400] rounded transition-all duration-200 font-nunito  py-1 px-4 lg:py-2 lg:px-0"
                      : "text-white  bg-transparent hover:text-darkPrimary  text-[12px] lg:text-[16px] lg:font-[400] rounded transition-all duration-200 font-nunito  py-1 px-4 lg:py-2 lg:px-0"
                  }`
                }
                to="/"
              >
                Home
              </NavLink>
              <NavLink
                className={({ isActive }) =>
                  `${
                    isActive
                      ? "text-darkPrimary  bg-transparent hover:text-darkPrimary  text-[12px] lg:text-[16px] lg:font-[400] rounded transition-all duration-200 font-nunito  py-1 px-4 lg:py-2 lg:px-0"
                      : "text-white  bg-transparent hover:text-darkPrimary  text-[12px] lg:text-[16px] lg:font-[400] rounded transition-all duration-200 font-nunito  py-1 px-4 lg:py-2 lg:px-0"
                  }`
                }
                to="/explore-projects"
              >
                Explore Projects
              </NavLink>

              <NavLink
                className={({ isActive }) =>
                  `${
                    isActive
                      ? "text-darkPrimary  bg-transparent hover:text-darkPrimary  text-[12px] lg:text-[16px] lg:font-[400] rounded transition-all duration-200 font-nunito  py-1 px-4 lg:py-2 lg:px-0"
                      : "text-white  bg-transparent hover:text-darkPrimary  text-[12px] lg:text-[16px] lg:font-[400] rounded transition-all duration-200 font-nunito  py-1 px-4 lg:py-2 lg:px-0"
                  }`
                }
                to="/find-mate"
              >
                Finds Mates
              </NavLink>

              <NavLink
                className={({ isActive }) =>
                  `${
                    isActive
                      ? "text-darkPrimary  bg-transparent hover:text-darkPrimary  text-[12px] lg:text-[16px] lg:font-[400] rounded transition-all duration-200 font-nunito  py-1 px-4 lg:py-2 lg:px-0"
                      : "text-white  bg-transparent hover:text-darkPrimary  text-[12px] lg:text-[16px] lg:font-[400] rounded transition-all duration-200 font-nunito  py-1 px-4 lg:py-2 lg:px-0"
                  }`
                }
                to="/reviews"
              >
                Ask / Query
              </NavLink>

              {!response && (
                <div id="login" className="relative github_wrapper">
                  <img
                    style={{ width: 24 }}
                    className="w-8 absolute top-[13px] left-[5px]"
                    src="https://static-00.iconduck.com/assets.00/github-icon-2048x2048-dpporae2.png"
                  />
                  <GitHubLogin
                    className="github_btn"
                    clientId={process.env.REACT_APP_CLIENT_ID}
                    onSuccess={onSuccess}
                    redirectUri=""
                  />
                </div>
              )}
              {response && (
                <>
                  <FillButton
                    pysm="py-1"
                    pxsm="px-4"
                    py="lg:py-1"
                    px="lg:px-7"
                    onClick={openAddProject}
                  >
                    Add Project
                  </FillButton>
                  <Dropdown
                    avatar={response.avatar}
                    name={response.name}
                    handleSignOut={handleSignOut}
                    login={response?.login}
                  />
                </>
              )}
            </div>
            {/* desktop navbar end */}

            <DeviceNavbar
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              response={response}
              handleSignOut={handleSignOut}
              openAddProject={openAddProject}
              onSuccess={onSuccess}
              setIsDropOpen={setIsDropOpen}
            />
          </div>
        </div>
        {isAddProjectOpen && <AddProject onClose={onclose} />}
      </div>
    </>
  );
};

export default Navbar;
