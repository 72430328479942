import React from 'react'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import MyResponsivePie from "./pie-graph";

const LanguageAnalaysisRow = ({
  user_profile,
  dignal,
  straight,
  isMobile,
  newLanguage,
  newLanguageBytes,
}) => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-12 mt-12 p-2 lg:p-8 ">
      <div className="">
        <p className="text-white font-bold font-mona_sans_thin  leading-[20px] text-[12px] lg:leading-[20px] lg:text-[16px]">
          {user_profile?.language?.length > 0
            ? "Repo based on language"
            : "No Languages added for analysis"}
        </p>
        <div className=" rounded-xl border border-solid border-[#070707] bg-backGroundCard shadow-[0px_0px_5px_0px_#FFFFFF29] mt-4">
          {user_profile?.language ? (
            <MyResponsivePie
              data={user_profile?.language}
              dignal={dignal}
              straight={straight}
              isMobile={isMobile}
            />
          ) : (
            <SkeletonTheme baseColor="#202020" highlightColor="#444">
              <Skeleton className="h-[28rem]" />
            </SkeletonTheme>
          )}
          {newLanguage.length > 0 && (
            <p className="text-white text-center font-nunito  text-[12px] leading-[20px] lg:text-[17px] lg:leading-[30px] pl-4 pb-4">
              Most used language is{" "}
              <span className="text-primary font-bold">
                {" "}
                {newLanguage[0]?.label}
              </span>{" "}
              {newLanguage.length > 1 ? (
                <span>
                  followed by
                  <span className="text-primary font-bold">
                    {" "}
                    {newLanguage[1]?.label}
                  </span>
                </span>
              ) : (
                ""
              )}{" "}
              {newLanguage.length > 2 ? (
                <span>
                  {" "}
                  &{" "}
                  <span className="text-primary font-bold">
                    {newLanguage[2]?.label}
                  </span>
                </span>
              ) : (
                ""
              )}
            </p>
          )}
        </div>
      </div>
      <div className="">
        <p className="text-white  font-bold font-mona_sans_thin  leading-[20px] text-[12px] lg:leading-[20px] lg:text-[16px]">
          {user_profile?.languageBytes?.length > 0
            ? "Language analysis Size wise"
            : "No languages for analysis"}
        </p>
        <div className=" rounded-xl border border-solid border-[#070707] bg-backGroundCard shadow-[0px_0px_5px_0px_#FFFFFF29] mt-4">
          {user_profile?.languageBytes ? (
            <MyResponsivePie
              dignal={dignal}
              straight={straight}
              isMobile={isMobile}
              data={user_profile?.languageBytes}
            />
          ) : (
            <SkeletonTheme baseColor="#202020" highlightColor="#444">
              <Skeleton className="h-[28rem]" />
            </SkeletonTheme>
          )}

          {newLanguageBytes.length > 0 && (
            <p className="text-white text-center font-nunito  text-[12px] leading-[20px] lg:text-[17px] lg:leading-[30px] pl-4 pb-4">
              Most used language is{" "}
              <span className="text-primary font-bold">
                {" "}
                {newLanguageBytes[0]?.label}
              </span>{" "}
              {newLanguageBytes.length > 1 ? (
                <span>
                  followed by
                  <span className="text-primary font-bold">
                    {" "}
                    {newLanguageBytes[1]?.label}
                  </span>
                </span>
              ) : (
                ""
              )}{" "}
              {newLanguageBytes.length > 2 ? (
                <span>
                  {" "}
                  &{" "}
                  <span className="text-primary font-bold">
                    {newLanguageBytes[2]?.label}
                  </span>
                </span>
              ) : (
                ""
              )}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default LanguageAnalaysisRow;
