import React,{ useEffect, useState } from "react";
import { ImSearch } from "react-icons/im";
import { useDebounce } from "use-debounce";
import { searchUser } from "../store/action/user-action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CLEAR_SEARCH_DATA } from "../store/constant/user";
const SearchBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [text, setText] = useState("");
  const [debouncedText] = useDebounce(text, 400);
  const { search_list } = useSelector((state) => state.userDetails);
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/view-profile/${text}`);
  };
  useEffect(() => {
    if (debouncedText.length !== 0) {
      dispatch(searchUser(debouncedText));
    }
  }, [debouncedText]);

  useEffect(() => {
    if (text.length === 0) {
      dispatch({
        type: CLEAR_SEARCH_DATA,
      });
    }
  }, [text]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="relative">
      <div className="px-[1px] lg:px-[2px] py-[1px] lg:py-[2px]  bg-gradient-to-r from-emerald-300 via-[#70C1CC] to-emerald-300  text-emerald-300 rounded-lg">
        <input
          className="block w-full bg-gray-800 px-2 lg:px-4 py-1 lg:py-3 ps-4 lg:ps-8 text-[14px] lg:text-[18px]  text-emerald-300	 rounded-lg  	 outline-none"
          placeholder="Search External users"
          required
          onChange={(e) => {
            setText(e.target.value);
          }}
        />
      </div>
        
        <button
          type="submit"
          className=" hover:text-white hover:bg-grey text-gray-900 absolute p-[5px] lg:p-[18px] transition-all duration-200 right-[1px] rounded-xl bottom-[2px] lg:bottom-0 "
        >
          <ImSearch className="text-white"/>
        </button>
      </div>
      {search_list?.length !== 0 && (
        <div className=" w-10/12 z-9 lg:w-[20rem] max-h-80 overflow-y-scroll absolute p-4 mt-4 border shadow-[0px_0px_5px_0px_#ffffff29]  rounded-xl border-solid border-[#070707] bg-backGroundCard ">
          <ui className="list-none   ">
            {search_list?.map((item, index) => (
              <li
                onClick={() => navigate(`/view-profile/${item.login}`)}
                key={index}
                className="flex text-grey hover:text-white  transition-all duration-200 font-nunito  text-[12px] leading-[20px] lg:text-[17px] lg:leading-[30px] cursor-pointer my-1 lg:my-3 "
              >
                <div className="rounded-full	border-2 border-white w-[30px] h-fit flex justify-center items-center overflow-hidden mr-2">
                  <img
                    src={item.avatar_url}
                    className="w-[50px] rounded-full"
                  />
                </div>
                {item.login}
              </li>
            ))}
          </ui>
        </div>
      )}
    </form>
  );
};

export default SearchBar;
