import React from "react";
import RecentActivity from "./recentActivity";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const RecentActivityCard = ({ user_profile, user }) => {
  return (
    <div className="">
      <p className="text-white font-bold font-mona_sans_thin  leading-[20px] text-[12px] lg:leading-[20px] lg:text-[16px]">
        {user_profile?.recentActivites?.length > 0
          ? "My Recents activites"
          : "No recent activities"}
      </p>
      <div className="h-[28rem] overflow-y-scroll rounded-xl border border-solid border-[#070707] bg-backGroundCard shadow-[0px_0px_5px_0px_#FFFFFF29] mt-4">
        {user_profile ? (
          user_profile?.recentActivites?.map((item, index) => {
            return <RecentActivity data={item} avatar={user.avatar_url} />;
          })
        ) : (
          <SkeletonTheme baseColor="#202020" highlightColor="#444">
            <Skeleton className="h-[28rem]" />
          </SkeletonTheme>
        )}
      </div>
    </div>
  );
};

export default RecentActivityCard;
