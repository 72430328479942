import React, { useState } from "react";
import { TECollapse } from "tw-elements-react";
import { FaDotCircle } from "react-icons/fa";
import { timeAgo } from "../../utils";
import { NavLink } from "react-router-dom";
import "./reviews.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updateFeedbackStatus } from "../../store/action/feedback-action";

export default function Accordion({ data }) {
  const dispatch = useDispatch();

  const {
    userInfo: { response },
  } = useSelector((state) => state.userDetails);
  const login = response?.login;
  const [activeElement, setActiveElement] = useState("");
  const handleClick = (value) => {
    if (value === activeElement) {
      setActiveElement("");
    } else {
      setActiveElement(value);
    }
  };

  const handleClose = async (event, id) => {
    event.stopPropagation();
    dispatch(updateFeedbackStatus(id));
  };

  return (
    <>
      <div id="accordionExample">
        <div className=" scroll overflow-x-hidden py-2 pr-2">
          {data?.map((item, index) => (
            <div className="rounded-lg border shadow-[0px_0px_5px_0px_#ffffff29]  border-[#070707] bg-backGroundCard mb-4">
              <h2 className="mb-0" id="headingOne">
                <button
                  className={`${
                    activeElement === `element${index}` &&
                    `!text-primary-400 [box-shadow:inset_0_-1px_0_#4b556338]`
                  } group relative flex w-full items-center rounded-t-[15px] border-0  px-5 py-4 text-left text-base font-nunito transition [overflow-anchor:none] hover:z-[2]  outline-none bg-backGroundCard text-white`}
                  type="button"
                  onClick={() => handleClick(`element${index}`)}
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <div className="w-full flex items-start">
                    <img
                      className="border rounded-full"
                      src={item?.user?.avatar}
                      width={40}
                    />{" "}
                    <div className="w-full pl-4">
                      {" "}
                      <div className="flex ">
                        <h2 className="flex items-center">{item.title}</h2>
                        <span
                          className={`${
                            activeElement === `element${index}`
                              ? `rotate-[-180deg] -mr-1`
                              : `rotate-0 fill-white`
                          } ml-auto h-5 w-5 shrink-0  transition-transform duration-200 ease-in-out motion-reduce:transition-none fill-blue-300`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="h-6 w-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                            />
                          </svg>
                        </span>
                      </div>
                      <div className="flex flex-col md:flex-row items-start md:items-center ">
                        <p className="block text-grey font-nunito  text-[12px] leading-[20px] lg:text-[15px] lg:leading-[30px]  md:flex">
                          open on{" "}
                          <span className="text-primary mx-1">
                            {timeAgo(item?.createdAt)}
                          </span>{" "}
                          by
                          <NavLink
                            className="text-primary cursor-pointer mx-1"
                            to={`/view-profile/${item?.user?.login}`}
                          >
                            {item?.user?.login}
                          </NavLink>
                        </p>

                        <div className="ml-0 md:ml-3 mt-1 md:mt-0">
                          {item.status === "close" ? (
                            <span className="bg-red-700 text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded  border border-red-400">
                              Close
                            </span>
                          ) : (
                            <span className="bg-green-700 text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded  border border-green-400">
                              {item.status ?? "Open"}
                            </span>
                          )}{" "}
                          &nbsp;&nbsp;
                          {item.type?.includes("Bug") ? (
                            <span className="bg-red-700 text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded  border border-red-400">
                              Bug
                            </span>
                          ) : (
                            <span className="bg-purple-700 text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded  border border-purple-400">
                              Feature
                            </span>
                          )}
                          {login === "amirSohel007" && (
                            <>
                              {item?.status !== "close" && (
                                <span
                                  onClick={(e) => handleClose(e, item._id)}
                                  className=" text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded  border border-green-400 bg-teal-600	"
                                >
                                  mark it close
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
              </h2>
              <TECollapse
                show={activeElement === `element${index}`}
                className="!mt-0 !rounded-b-none !shadow-none"
              >
                <div className="px-5 py-4 text-white font-nunito">
                  {item.description}
                </div>
              </TECollapse>
            </div>
          ))}
        </div>
        ̦
      </div>
    </>
  );
}
