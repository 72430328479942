import React from 'react'
import Textbutton from "../../core/text-button";
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import Search from "../../core/searchBar";
import "./Homepage.css";
import { motion, useInView } from "framer-motion";
import { useRef, useState, useEffect } from "react";
const FirstContributors = () => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [isMobile] = useState(() => window.innerWidth < 700);
  const [height, setHeight] = useState("");
  useEffect(() => {
    if (isMobile) {
      setHeight("185px");
    } else {
      setHeight("364px");
    }
  }, [isMobile]);
  return (
    <div className=" px-8 pt-6 lg:pt-0 lg:px-24">
      <div className="flex h-[185px] lg:h-[364px]">
        <div ref={ref} className="relative">
          {isInView && (
            <motion.div
              initial={{ height: "0px" }}
              animate={{ height: height }}
              exit={{ height: height }}
              transition={{
                duration: "1.5",
              }}
              className=" h-[185px] lg:h-[364px] firstContributorBackground w-[5px] lg:w-[8px] mt-8 lg:mt-0 mr-3 lg:mr-5"
            ></motion.div>
          )}
          <img
            data-aos="fade-down"
            src="Images/circle.svg"
            className="text-white absolute  w-3 lg:w-5 left-[-3px] lg:left-[-5px] top-[0.75rem] lg:top-[9.50rem]"
          />
        </div>

        <div className="w-full mt-[0.25rem] lg:mt-[9rem] relative">
          <p
            data-aos="fade-left"
            data-aos-duration="4000"
            className="text-secondary font-nunito  text-[18px] lg:text-[30px] lg:leading-[30px]  "
          >
            Search
          </p>
          <h3
            data-aos="fade-left"
            data-aos-duration="6000"
            className="text-white font-lato font-bold   text-[25px] leading-[30px] lg:leading-[50px] lg:text-[40px] lg:w-6/12 mt-4 "
          >
            Search non-registered user
          </h3>
          <p
            data-aos="fade-left"
            data-aos-duration="8000"
            className="text-grey text-[12px] lg:text-[15px] font-nunito  leading-[20px] lg:leading-[30px] lg:w-10/12 mt-8"
          >
            Search and Connect with Non-Registered Users to Catalyze / expand
            Collaboration!
          </p>

          {/* <div className="mt-10">
            <Textbutton onClick={() => navigate(`/find-mate`)}>
              <div className="flex items-center">
                Find Contributors &nbsp;&nbsp;
                <FaArrowRightLong className="text-white" />
              </div>
            </Textbutton>
          </div> */}
        </div>
      </div>
      <div className="flex flex-col justify-start items-start h-[250px] lg:h-[400px] relative  px-[1rem] py-[1.25rem] lg:px-[3rem] lg:py-[4rem] bg-[#12181F] w-full lg:w-11/12 lg:ml-[-1.75rem] mt-4 lg:mt-0 rounded-lg">
        {/* <img
          src="Images/FirstContributorSVG.svg"
          className="w-[124px] lg:w-[400px]  "
        /> */}
        <p
          data-aos="fade-left"
          data-aos-duration="8000"
          className="text-grey text-[12px] lg:text-[15px] font-nunito  leading-[20px] lg:leading-[30px] lg:w-10/12 mt-8"
        >
          Expand your network beyond registered members. With our user-friendly
          search feature, connecting with external users has never been easier
        </p>
        <div className="z-9 w-full md:w-1/2 mt-8">
          <Search />
        </div>
        <img
          data-aos="fade-left"
          src="Images/FirstContributorPNG.png"
          className="w-[160px] lg:w-[400px] absolute right-[-0.75rem] bottom-[-63px] lg:right-14 lg:bottom-[-36px] "
        />
      </div>
    </div>
  );
};

export default FirstContributors;
