import { ResponsivePie } from "@nivo/pie";
import React, { useEffect, useState } from "react";

const MyResponsivePie = ({ data, dignal, straight, isMobile }) => {
  const [legend, setLegends] = useState([]);
  const [margin, setMargin] = useState({
    top: 40,
    right: 70,
    bottom: 40,
    left: 90,
  });

  useEffect(() => {
    if (!isMobile) {
      setLegends([
        {
          anchor: "right",
          direction: "column",
          justify: false,
          translateX: 20,
          translateY: -11,
          itemsSpacing: 0,
          itemWidth: 105,
          itemHeight: 39,
          itemTextColor: "#999",
          itemDirection: "left-to-right",
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "white",
              },
            },
          ],
        },
      ]);
      setMargin({ top: 40, right: 80, bottom: 80, left: -8 });
    }
  }, [isMobile]);
  return (
    <div className="h-[400px]">
      <ResponsivePie
        data={data}
        margin={margin}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        enableArcLinkLabels={false}
        arcLinkLabelsStraightLength={dignal}
        arcLinkLabelsDiagonalLength={straight}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="white"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: "color",
          modifiers: [["darker", 2]],
        }}
        arcLabel={(e) => e.data.size}
        defs={[
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "rgba(255, 255, 255, 0.3)",
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "rgba(255, 255, 255, 0.3)",
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        fill={[
          {
            match: {
              id: "JavaScript",
            },
            id: "dots",
          },
          {
            match: {
              id: "Vue",
            },
            id: "dots",
          },
          {
            match: {
              id: "Go",
            },
            id: "dots",
          },
          {
            match: {
              id: "Python",
            },
            id: "dots",
          },
          {
            match: {
              id: "CSS",
            },
            id: "lines",
          },
          {
            match: {
              id: "TypeScript",
            },
            id: "lines",
          },
          {
            match: {
              id: "HTML",
            },
            id: "lines",
          },
          {
            match: {
              id: "others",
            },
            id: "lines",
          },
        ]}
        legends={legend}
      />
    </div>
  );
};

export default MyResponsivePie;
