import {
  GET_ALL_USERS,
  GET_USER_DATA,
  LOGIN_SUCCESS,
  USER_LOGOUT,
  CLEAR_USER_DATA,
  SEARCH_USER,
  CLEAR_SEARCH_DATA,
  GET_GRAPH_DATA,
  CLEAR_GRAPH_DATA,
  GRAPH_STATUS,
  USER_STATUS,
} from "../constant/user";

const initialState = {
  userInfo: {},
  user_profile: {},
  search_list: [],
  allUsers: [],
  graphInfo: [],
  graphStatus: "",
  userStatus: "200",
};

const userDetailsReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        userInfo: action.payload,
      };
    case USER_LOGOUT:
      return {
        ...state,
        userInfo: action.payload,
      };
    case GET_ALL_USERS:
      return {
        ...state,
        allUsers: action.payload,
      };
    case GET_USER_DATA:
      return {
        ...state,
        user_profile: action.payload,
      };
    case SEARCH_USER:
      return {
        ...state,
        search_list: action.payload,
      };
    case CLEAR_USER_DATA:
      return {
        ...state,
        user_profile: {},
      };
    case CLEAR_SEARCH_DATA:
      return {
        ...state,
        search_list: [],
      };
    case GET_GRAPH_DATA:
      return {
        ...state,
        graphInfo: action.payload,
      };
    case CLEAR_GRAPH_DATA:
      return {
        ...state,
        graphInfo: [],
      };
    case GRAPH_STATUS:
      return {
        ...state,
        graphStatus: action.payload,
      };
    case USER_STATUS:
      return {
        ...state,
        userStatus: action.payload,
      };
    default:
      return state;
  }
};

export default userDetailsReducer;
