import toast from "react-hot-toast";
import notifications from "../../components/notifications";
import dataProvider from "../../service";
import {
  ADD_FEEDBACK,
  FEEDBACK_ADDED,
  GET_FEEDBACK,
} from "../constant/post";
import { createHash } from "../../utils";

export const addFeedback = (payload, navigation) => async (dispatch) => {
  try {
    const data = dataProvider.post("feedback/add-feedback", payload, {
      headers:{
        'Authorization': createHash(payload.user),
      }
    });
    toast.promise(data, {
      loading: "Adding Feedback ....",
      success: () => {
        notifications("success", "Your request has been sent.");
        dispatch(getFeedback());
        navigation('/reviews')
        dispatch({
          type: ADD_FEEDBACK,
          payload: data,
        });
        dispatch({
          type: FEEDBACK_ADDED,
          payload: true
        })
      },
      error: (error) => {
        console.log(error);
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export const getFeedback = (payload) => async (dispatch) => {
  try {
    const { data } = await dataProvider.get("feedback/get-feedback", payload);
    dispatch({
      type: GET_FEEDBACK,
      payload: data?.result
    })
  } catch (error) {
    console.log(error);
  }
};

export const updateFeedbackStatus = (payload) => async (dispatch) => {
  try {
    const data = await dataProvider.get(`feedback/change-status/?id=${payload}`)
    if (data.status === 200) {
      dispatch(getFeedback())
      notifications('success', 'Status has updated.')
    }
  } catch (error) {
    console.log(error)
  }
}