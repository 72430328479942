import React from 'react'
import "./Homepage.css";
import DiscriptionCardWithSIdeImage from "./discriptions/discriptionCardWithSIdeImage";
import { useNavigate } from "react-router-dom";
import { motion, useInView } from "framer-motion";
import { useRef, useState, useEffect } from "react";
const SecondContributors = () => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [isMobile] = useState(() => window.innerWidth < 700);
  const [height, setHeight] = useState("");
  useEffect(() => {
    if (isMobile) {
      setHeight("200px");
    } else {
      setHeight("450px");
    }
  }, [isMobile]);
  return (
    <div className=" px-8 mt-8 lg:mt-[-2.5rem] lg:px-24">
      <div className="flex  h-[200px] lg:h-[450px]">
        <div ref={ref} className="relative">
          {isInView && (
            <motion.div
              initial={{ height: "0px" }}
              animate={{ height: height }}
              exit={{ height: "0px" }}
              transition={{
                duration: "1.5",
              }}
              className=" h-[200px] lg:h-[450px] secondContributorBackground w-[5px] lg:w-[8px] mt-8 lg:mt-10 mr-3 lg:mr-5"
            ></motion.div>
          )}
          <img
            data-aos="fade-down"
            src="Images/circle.svg"
            className="text-white absolute  w-3 lg:w-5 left-[-3px] lg:left-[-5px] top-[0.75rem] lg:top-[13.50rem]"
          />
        </div>

        <div className="w-full mt-[0.5rem] lg:mt-[13rem] relative">
          <p
            data-aos="fade-left"
            data-aos-duration="4000"
            className="text-secondary font-nunito  text-[18px] lg:text-[30px] lg:leading-[30px]  "
          >
            Contributors
          </p>
          <h3
            data-aos="fade-left"
            data-aos-duration="6000"
            className="text-white font-lato font-bold   text-[25px] leading-[30px] lg:leading-[50px] lg:text-[40px] lg:w-6/12 mt-4 "
          >
            Grow Your Audience
          </h3>
          <p
            data-aos="fade-left"
            data-aos-duration="8000"
            className="text-grey text-[12px] lg:text-[15px] font-nunito  leading-[20px] lg:leading-[30px] lg:w-10/12 mt-8"
          >
            Discover the Impact Our Contributors are Making. Meet Registered users and Explore Exciting Projects!
          </p>
        </div>
      </div>
      <DiscriptionCardWithSIdeImage
        buttonName="Find Contributors"
        imageName="Images/SecondContributorsSVG.png"
        // onClick={() => navigate(`/explore-projects`)}
        onClick={() => navigate(`/find-mate`)}
        description={
          "Reach new heights. With a vibrant community boasting daily unique visitors of 50+, showcase your projects to a wider audience and attract potential contributors Get in touch and empower your coding life, today!"
        }
      />
    </div>
  );
};

export default SecondContributors;
