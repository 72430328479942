import React from 'react'
import "./footer.css";

const Footer = () => {
  return (
    <div className="flex-col lg:flex-row lg:flex w-full px-4 lg:px-16 py-6 mt-2 lg:mt-4 bg-[#161B22]">
      <div className="justify-between	w-full hidden md:flex flex-row">
        <p className="text-grey text-[12px] lg:text-[15px] font-nunito  py-2 ">
          © 2024 - 2025 Projectmate.
        </p>
        <p className="text-grey text-[12px] lg:text-[15px] text-center md:text-left font-nunito  py-2 ">
          Presented by{" "}
          <a
            className="text-primary"
            href="https://www.linkedin.com/in/amirsohel007/"
            target="_blank"
          >
            Amir Sohel
          </a>{" "}
          with contributions from{" "}
          <a
            className="text-primary"
            href="https://www.linkedin.com/in/vishal-kumar-694bab189/"
            target="_blank"
          >
            Vishal Kumar Rathore
          </a>
          . ❤️
        </p>

        <p className="text-grey text-[12px] lg:text-[15px] font-nunito  py-2 ">
          Terms & Privacy Policy
        </p>
      </div>
      <div className="justify-between	w-full flex md:hidden flex-col">
        <p className="text-grey text-[12px] lg:text-[15px] text-center md:text-left font-nunito  py-2 ">
          Presented by{" "}
          <a
            className="text-primary"
            href="https://www.linkedin.com/in/amirsohel007/"
            target="_blank"
          >
            Amir Sohel
          </a>{" "}
          with contributions from{" "}
          <a
            className="text-primary"
            href="https://www.linkedin.com/in/vishal-kumar-694bab189/"
            target="_blank"
          >
            Vishal Kumar Rathore
          </a>
          . ❤️
        </p>
        <div className="flex justify-around">
          <p className="text-grey text-[12px] lg:text-[15px] font-nunito  py-2 ">
            © 2024 - 2025 Projectmate.
          </p>

          <p className="text-grey text-[12px] lg:text-[15px] font-nunito  py-2 ">
            Terms & Privacy Policy
          </p>
        </div>
      </div>
      {/* {open && (
        <div className="fixed left-0 right-0 form">
          <Contactus setIsOpen={setIsOpen} />
        </div>
      )} */}
    </div>
  );
};

export default Footer;
