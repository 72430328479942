import React from 'react'

const FillButton = ({
  children,
  pysm,
  pxsm,
  px,
  py,
  round = "rounded",
  onClick,
}) => {
  pysm = pysm ? pysm : py;
  pxsm = pxsm ? pxsm : px;

  const css = `bg-purple hover:bg-darkPurple text-white text-[12px] lg:text-[16px] lg:font-[400]  transition-all duration-200 font-nunito  ${px}  ${py}  ${pysm} ${pxsm}   ${round} `;

  return (
    <button className={css} onClick={onClick}>
      {children}
    </button>
  );
};

export default FillButton;
