import React from 'react'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import OfficialIcon from "../../static/img/official.png";
import { FaStar } from "react-icons/fa";

function Banner({
  user,
  followers,
  following,
  public_repos,
  user_profile,
  getTotalStar,
  forkCount,
}) {
  return (
    <div className="bgImg flex  lg:items-end pl-5 pr-5 lg:pr-16 pt-5 lg:pt-0 lg:pl-16 pb-10 ">
      <div className="mb-[-5rem]">
        {user ? (
          <img
            src={user?.avatar_url}
            className="text-white h-[110px] lg:h-[286px] rounded-xl "
          />
        ) : (
          <SkeletonTheme baseColor="#202020" highlightColor="#444">
            <Skeleton className="h-[5rem] w-[5rem] md:h-[19rem] md:w-[19rem]" />
          </SkeletonTheme>
        )}
      </div>
      <div className="ml-6 w-[58%] lg:w-[74%]">
        {user ? (
          <p className="text-white font-mona_sans_thin  leading-[35px] text-[25px] lg:leading-[48px] lg:text-[30px]">
            {user?.name}
            {["amirSohel007", "vk-rathore"].includes(user.login) && (
              <img
                style={{ width: 20 }}
                className="inline-block ml-2"
                src={OfficialIcon}
              />
            )}
          </p>
        ) : (
          <SkeletonTheme baseColor="#202020" highlightColor="#444">
            <Skeleton className="h-8 w-40" />
          </SkeletonTheme>
        )}
        {user ? (
          <p className="text-primary font-nunito  text-[12px] leading-[20px] lg:text-[17px] lg:leading-[30px] ">
            {user?.bio ? user?.bio : "Please update your bio on Github "}
          </p>
        ) : (
          <SkeletonTheme baseColor="#202020" highlightColor="#444">
            <Skeleton className="h-5" />
          </SkeletonTheme>
        )}
        {user ? (
          <div className="flex flex-col lg:flex-row justify-between lg:items-center mt-3 pb-2 lg:border-b-2 lg:border-[#343434] w-full ">
            <p className="text-grey font-nunito  text-[12px] leading-[20px] lg:text-[17px] lg:leading-[30px] ">
              {user?.login}
            </p>
            {/* <div
          onClick={() => setEdit(true)}
          className="flex cursor-pointer justify-end w-[2.5rem] mt-2 lg:mt-0 text-white hover:text-primary"
        >
          <MdModeEditOutline />
        </div> */}
          </div>
        ) : (
          <SkeletonTheme baseColor="#202020" highlightColor="#444">
            <Skeleton className="h-5" />
          </SkeletonTheme>
        )}
        {/* {edit && (
      <div className="absolute top-[8rem]">
        <EditProfile setEdit={setEdit} />
      </div>
    )} */}
        {Object.keys(user_profile).length ? (
          <div className="grid grid-cols-2 gap-4 lg:gap-0 lg:grid-cols-6  mt-4 ml-[-8rem] lg:ml-0">
            <div className="mr-14">
              <p className="text-primary font-nunito  text-[12px] leading-[20px] lg:text-[17px] lg:leading-[30px] ">
                {followers}
              </p>

              <p className="text-grey font-nunito  text-[12px] leading-[20px] lg:text-[17px] lg:leading-[30px]">
                Followers
              </p>
            </div>
            <div className="mr-14">
              <p className="text-primary font-nunito  text-[12px] leading-[20px] lg:text-[17px] lg:leading-[30px] ">
                {following}
              </p>

              <p className="text-grey font-nunito  text-[12px] leading-[20px] lg:text-[17px] lg:leading-[30px]">
                Following
              </p>
            </div>
            <div className="mr-14">
              <p className="text-primary font-nunito  text-[12px] leading-[20px] lg:text-[17px] lg:leading-[30px] ">
                {public_repos}
              </p>

              <p className="text-grey font-nunito  text-[12px] leading-[20px] lg:text-[17px] lg:leading-[30px]">
                Repository
              </p>
            </div>
            <div className="mr-14">
              <p className="text-primary font-nunito  text-[12px] leading-[20px] lg:text-[17px] lg:leading-[30px] ">
                {user_profile?.recentActivites?.length}
              </p>

              <p className="text-grey font-nunito  text-[12px] leading-[20px] lg:text-[17px] lg:leading-[30px]">
                Commits
              </p>
            </div>
            <div className="mr-14">
              <p className=" flex items-center text-primary font-nunito  text-[12px] leading-[20px] lg:text-[17px] lg:leading-[30px] ">
                <FaStar />
                {getTotalStar}
              </p>

              <p className="text-grey font-nunito  text-[12px] leading-[20px] lg:text-[17px] lg:leading-[30px]">
                Stars
              </p>
            </div>
            <div className="mr-14">
              <p className="text-primary font-nunito  text-[12px] leading-[20px] lg:text-[17px] lg:leading-[30px] ">
                {forkCount}
              </p>

              <p className="text-grey font-nunito  text-[12px] leading-[20px] lg:text-[17px] lg:leading-[30px]">
                Fork Count
              </p>
            </div>
          </div>
        ) : (
          <SkeletonTheme baseColor="#202020" highlightColor="#444">
            <Skeleton className="h-5 " />
          </SkeletonTheme>
        )}
      </div>
    </div>
  );
}

export default Banner;
