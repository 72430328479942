import React from 'react'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const RecentActivity = ({ data, avatar }) => {
  const { date, message, repo } = data || {};

  return (
    <div className="  p-2  bg-backGroundCard ">
      <div className="flex">
        <div className=" w-2/12 lg:w-1/12">
          <img
            src={avatar}
            className="text-white h-[40px] lg:h-[43px] border border-solid border-[#efefef38] p-[2px] rounded-sm "
          />
        </div>
        <div className="lg:ml-4 w-10/12 lg:w-11/12">
          <p className="text-white font-bold font-mona_sans_thin  leading-[10px] text-[10px] lg:leading-[15px] lg:text-[12px]">
            {message} in <span className="text-[#ffad4d]">{repo}</span>
          </p>
          <p className="font-nunito text-primary text-[11px] font-bold  leading-[25px]">
            {date}
          </p>
        </div>
      </div>
    </div>
  );
};

export default RecentActivity;
