import React from 'react'
import { RxCross2 } from "react-icons/rx";
const Tags = ({ name, handleRemove, index, noCross }) => {
  return (
    <div className=" px-3 lg:px-4 bg-white w-fit rounded-full  text-mona_sans text-[12px] lg:text-[15px] leading-[30px] relative mx-1 my-2 lg:mx-2 ">
      {name}
      {!noCross && (
        <button
          onClick={() => handleRemove(index)}
          className="absolute bg-secondary hover:bg-[#7bc585] rounded-full text-white p-[1px] lg:p-[2px] top-[-2px] right-[-4px] "
        >
          <RxCross2 />
        </button>
      )}
    </div>
  );
};

export default Tags;
