import React, { useState } from "react";
import Dropdown from "./dropdown";
import { useDispatch, useSelector } from "react-redux";
import { addFeedback } from "../../store/action/feedback-action";
import notifications from "../notifications";
import { useNavigate } from "react-router-dom";

const FeatureForm = () => {
  const [state, setState] = useState({
    type: "Request a Feature",
    title: "",
    description: "",
  })
  const navigation = useNavigate()

  const { userInfo } = useSelector((state) => state.userDetails);
  const login = userInfo?.response?.login || "";
  const dispatch = useDispatch()

  const handleSubmitForm = () => {
    // Handling validation before dispatch actoin
    if (Object.keys(state).every((item) => state[item]))
      dispatch(addFeedback({ ...state, user: login }, navigation))
    else {
      notifications("error", "All fields are mandatory.");
      return;
    }
  }

  return (
    <div className="mt-8">
      <p className="text-white font-mona_sans_thin  leading-[16px] text-[16px] lg:leading-[20px] lg:text-[16px] mb-4">
        Select your type
      </p>
      <div className="mb-4">
        <Dropdown text={state.type} setText={setState} />
      </div>

      <p className="text-white font-mona_sans_thin  leading-[16px] text-[16px] lg:leading-[20px] lg:text-[16px] mb-4">
        Enter your title
      </p>
      <div className="px-[1px] lg:px-[2px] py-[1px] lg:py-[2px]  bg-gradient-to-r from-emerald-300 via-[#70C1CC] to-emerald-300  text-emerald-300 rounded-lg">
        <input
          className="block w-full bg-gray-800 px-2 lg:px-4 py-1 lg:py-2 ps-4 lg:ps-6 text-[12px] lg:text-[18px]  rounded-lg  outline-none"
          placeholder="Enter Title"
          required
          onChange={(e) => setState((data) => ({ ...data, title: e.target.value }))}
        />
      </div>
      <p className="text-white font-mona_sans_thin  leading-[16px] text-[12px] lg:leading-[20px] lg:text-[16px] mt-6 mb-4">
        Enter your discription
      </p>
      <div className="px-[1px] lg:px-[2px] py-[1px] lg:py-[2px]  bg-gradient-to-r from-emerald-300 via-[#70C1CC] to-emerald-300  text-emerald-300 rounded-lg">
        <textarea
          rows={4}
          className="block w-full bg-gray-800 px-2 lg:px-4 py-1 lg:py-2 ps-4 lg:ps-6 text-[14px] lg:text-[18px]  rounded-lg  outline-none"
          placeholder="Enter Title"
          required
          onChange={(e) => setState((data) => ({ ...data, description: e.target.value }))}
        />
      </div>
      <div className="flex justify-end">
        <button onClick={handleSubmitForm} className="bg-[#70C1CC] hover:bg-[#03a2b7] text-white text-[12px] lg:text-[16px] lg:font-[400]  transition-all duration-200 font-nunito px-4 py-2 rounded-lg mt-4">
          Submit
        </button>
      </div>
    </div>
  );
};
export default FeatureForm;
