import React, { useEffect, useState } from "react";
import Checkbox from "react-custom-checkbox";
import { FaCheck } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { ADD_TECH } from "../../store/constant/post";

const Languages = ({ name}) => {
  const [ischecked, setIschecked] = useState(false);
  const dispatch = useDispatch();

  const { tech } = useSelector((state) => state.postDetails);

  const handleOnCheck = (value, event) => {
    const {
      currentTarget: {
        control: { defaultValue: lang },
      },
    } = event || {};
    setIschecked(value);
    if (value) {
      if (!tech.includes(lang)) {
        dispatch({
          type: ADD_TECH,
          payload: [...tech,lang],
        });
      }
    } else {
      if (tech.includes(lang)) {
        const filteredArray = tech.filter((e) => e !== lang);
        dispatch({
          type: ADD_TECH,
          payload: [...filteredArray],
        });
      }
    }
  };

  useEffect(() => {
    if (tech.includes(name)) {
      setIschecked(true);
    } else {
      setIschecked(false);
    }
  }, [tech]);

  return (
    <div className="flex justify-between">
      <p className="text-grey font-nunito  text-[12px] leading-[20px] lg:text-[17px] lg:leading-[30px] mt-1  lg:mt-2 ">
        {name}
      </p>
      <Checkbox
        icon={<FaCheck color="#00FF02" size={14} />}
        borderColor="#AEB1B4"
        style={{ cursor: "pointer" }}
        checked={ischecked}
        onChange={handleOnCheck}
        value={name}
      />
    </div>
  );
};

export default Languages;
