import React from 'react'
import { FaCheck } from "react-icons/fa6";
import Checkbox from "react-custom-checkbox";
import { IoFilter } from "react-icons/io5";
import { MdFilterListOff } from "react-icons/md";
import "./filters.css";

function Filters({
  handleClear,
  handleOnCheck,
  response,
  ischecked,
  lang,
  Languages,
}) {
  return (
    <div className="w-full pr-8 ">
      <div className="flex justify-between items-center mr-[16px]">
        <p className="text-white font-mona_sans_thin  leading-[40px] text-[30px] lg:leading-[48px] lg:text-[30px]">
          <IoFilter />
        </p>
        <p
          onClick={handleClear}
          className="text-white cursor-pointer font-mona_sans_thin  leading-[40px] text-[30px] lg:leading-[48px] lg:text-[30px]"
        >
          <MdFilterListOff />
        </p>
      </div>

      <div className="grid grid-cols-1 divide-y divide-borderColor">
        <div className="mb-4 mt-2  lg:mt-4 mr-[22px]">
          {response && (
            <div className="flex justify-between">
              <label className="text-grey">{response?.login} Feeds</label>
              <Checkbox
                icon={<FaCheck color="#00FF02" size={14} />}
                borderColor="#AEB1B4"
                style={{ cursor: "pointer" }}
                checked={ischecked}
                onChange={handleOnCheck}
              />
            </div>
          )}
        </div>
        <div className="mb-6">
          <p className="text-primary font-nunito  text-[12px] leading-[20px] lg:text-[17px] lg:leading-[30px] mt-2  lg:mt-4 ">
            Languages
          </p>
          <div className="pr-4 scroll">
            {lang.map((i) => (
              <Languages name={i} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Filters;
