import Tags from "./tags";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { timeAgo } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { deleteProject } from "../store/action/post-action";
import OfficialIcon from "../static/img/official.png";
import MyShareComponent from "./share";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { IsDevelopment } from "../config";
import { useNavigate } from "react-router-dom";

const ExploreProjectCard = ({ data, noCross, nodelete }) => {
  const [share, setShare] = useState(false);
  const { userInfo } = useSelector((state) => state.userDetails);
  const _id = userInfo && userInfo?.response?._id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { title, description, tech, user, url, createdAt } = data || {};

  const repoString = url?.split("https://github.com/")[1];
  const repoName = repoString?.split("/")[1];

  const hostURL = IsDevelopment
    ? "http://localhost:3000/"
    : "https://projectbuddy.tech/";
  const copyURL = `${hostURL}explore-projects?id=${repoName}`;

  const scrollRef = useRef(null);
  const location = useLocation();

  const handleDelete = (id) => {
    dispatch(deleteProject(id));
  };

  useEffect(() => {
    setTimeout(() => {
      const urlParams = new URLSearchParams(location.search);
      const postId = urlParams.get("id");
      // If ID exists in URL, scroll to the corresponding post
      if (postId && scrollRef.current) {
        const postElement = document.querySelector(`#${postId}`);
        if (postElement) {
          // Scroll to the element
          postElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    }, 500);
  }, [location.search, data]);

  return (
    <>
      {data && (
        <div
          className="bg-backGroundCard grid grid-cols-1 divide-y divide-borderColor p-4 lg:p-6 mb-4 shadow-[0px_0px_5px_0px_#ffffff29] rounded-xl"
          id={`${repoName}`}
          ref={scrollRef}
        >
          <>
            <div className="flex justify-between lg:justify-end pb-2 lg:pb-4 relative">
              <a href={url} target="_blank">
                <button className="font-nunito  text-[10px] lg:text-[13px] font-bold text-grey leading-[12px] lg:leading-[30px]  flex items-center mx-0 lg:mx-6 hover:text-white">
                  <img
                    src="Images/contributeSVG.svg"
                    className="w-4 h-5 lg:w-6 lg:h-6 mr-2"
                  />
                  Contribute
                </button>
              </a>
              <button className="font-nunito  text-[10px] lg:text-[13px] font-bold text-grey leading-[12px] lg:leading-[30px]  flex items-center mx-0 lg:mx-6 hover:text-white">
                <img
                  src="Images/timeSVG.svg"
                  className="w-4 h-5 lg:w-5 lg:h-5 mr-2"
                />
                {createdAt && timeAgo(createdAt)}
              </button>
              <button
                onClick={() => setShare(true)}
                className="font-nunito  text-[10px] lg:text-[13px] font-bold text-grey leading-[12px] lg:leading-[30px]  flex items-center mx-0 lg:mx-6 hover:text-white"
              >
                <img
                  src="Images/shareSVG.svg"
                  className="w-4 h-5 lg:w-5 lg:h-5 mr-2"
                />
                Share
              </button>
              {user?._id === _id && !nodelete && (
                <button
                  onClick={() => handleDelete(data?._id)}
                  className="font-nunito  text-[10px] lg:text-[13px] font-bold text-grey leading-[12px] lg:leading-[30px]  flex items-center mx-0 lg:mx-6 hover:text-white"
                >
                  <img
                    src="Images/deleteIcon.svg"
                    className="w-4 h-5 lg:w-5 lg:h-5 mr-2"
                  />
                  Delete
                </button>
              )}
            </div>

            <div className="">
              <h2 className="font-nunito  text-[18px] lg:text-[25px]  text-white leading-[30px] mt-2 lg:mt-4">
                {title ? (
                  title
                ) : (
                  <SkeletonTheme
                    baseColor="#202020"
                    highlightColor="#444"
                    width="35%"
                  >
                    <Skeleton />
                  </SkeletonTheme>
                )}
              </h2>
              <div className="flex my-2 lg:my-4">
                {user ? (
                  <div className="rounded-full	border-2 border-white w-[55px] h-fit flex justify-center items-center overflow-hidden mr-2">
                    <img
                      src={user?.avatar}
                      className="w-[50px] h-[50px] rounded-full"
                    />
                  </div>
                ) : (
                  <SkeletonTheme
                    baseColor="#202020"
                    highlightColor="#444"
                    height="3.5rem"
                  >
                    <p className=" w-[55px]">
                      <Skeleton circle={true} />
                    </p>
                  </SkeletonTheme>
                )}

                <div className="ml-2 mt-1">
                  <p
                    className="font-nunito  text-[15px] font-bold text-white leading-[30px] cursor-pointer"
                    onClick={() =>
                      user?.login && navigate(`/view-profile/${user?.login}`)
                    }
                  >
                    {user ? (
                      user?.name
                    ) : (
                      <SkeletonTheme baseColor="#202020" highlightColor="#444">
                        <Skeleton className="w-[100px]" />
                      </SkeletonTheme>
                    )}
                  </p>
                  <p
                    className="font-nunito flex items-center text-[13px] font-bold text-grey leading-[30px] cursor-pointer"
                    onClick={() =>
                      user?.login && navigate(`/view-profile/${user?.login}`)
                    }
                  >
                    {user?.login ? (
                      <>
                        {["amirSohel007"].includes(user.login) && (
                          <img
                            className="mr-1"
                            style={{ width: 15, height: 15 }}
                            src={OfficialIcon}
                          />
                        )}
                        {user?.login}
                      </>
                    ) : (
                      <SkeletonTheme baseColor="#202020" highlightColor="#444">
                        <Skeleton className="w-[100px]" />
                      </SkeletonTheme>
                    )}
                  </p>
                </div>
              </div>
              <p className="font-nunito  text-[13px] lg:text-[16px]  text-grey leading-[20px] lg:leading-[25px]">
                {description ? (
                  description
                ) : (
                  <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    <Skeleton count={3} />
                  </SkeletonTheme>
                )}
              </p>
              <div className="flex flex-wrap mt-2 lg:mt-4">
                {tech?.map((item) => (
                  <Tags name={item} noCross={noCross} />
                ))}
              </div>
            </div>
          </>
          {share && (
            <div className="fixed share_modal">
              <MyShareComponent
                url={copyURL}
                setShare={setShare}
                title={title}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ExploreProjectCard;
