import axios from "axios";
import { IsDevelopment } from "../config";

const dataProvider = axios.create({
  baseURL: IsDevelopment
    ? "http://localhost:8001/api/"
    : "https://projectbuddy-api.up.railway.app/api/",
  timeout: 50000,
});

export default dataProvider;
