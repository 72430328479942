import dataProvider from "../../service";
import {
  LOGIN_SUCCESS,
  USER_LOGOUT,
  GET_ALL_USERS,
  GET_USER_DATA,
  SEARCH_USER,
  GET_GRAPH_DATA,
  GRAPH_STATUS,
  USER_STATUS,
} from "../constant/user";
import { createHash } from "../../utils";

export const getAllUsers = () => async (dispatch) => {
  try {
    const { data } = await dataProvider.get("user/get_users");
    if (data?.length >= 1) {
      dispatch({
        type: GET_ALL_USERS,
        payload: data,
      });
    }
  } catch (error) {
    console.error(error);
  }
};
export const onSuccessLogin = (payload) => async (dispatch) => {
  try {
    const { data } = await dataProvider.post("auth/get_access_token", {
      code: payload,
    });
    localStorage.setItem("userInfo", JSON.stringify(data));
    dispatch({
      type: LOGIN_SUCCESS,
      payload: data,
    });
    dispatch(getAllUsers());
  } catch (error) {}
};

export const onUserSignOut = () => async (dispatch) => {
  dispatch({
    type: USER_LOGOUT,
    payload: {},
  });
  localStorage.removeItem("userInfo");
};

export const getUserData = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_DATA,
      payload: payload,
    });
    dispatch({
      type: USER_STATUS,
      payload: "200",
    });
  } catch (error) {
    console.log(error.message);
    dispatch({
      type: USER_STATUS,
      payload: "500",
    });
  }
};

export const searchUser = (payload) => async (dispatch) => {
  try {
    const data = await dataProvider.get(`user/search?user=${payload}`);
    console.log({ data });
    if (data) {
      dispatch({
        type: SEARCH_USER,
        payload: data.data.items,
      });
    }
  } catch (error) {
    console.error(error);
  }
};

export const getGraphData = (payload) => async (dispatch) => {
  try {
    const { data } = await dataProvider.get(
      `user/user-graph/?user=${payload.replace(/\s/g, '')}`,
      {
        headers: {
          Authorization: createHash(payload),
        },
      }
    );
    if (data) {
      dispatch({
        type: GET_GRAPH_DATA,
        payload: data,
      });
      dispatch({
        type: GRAPH_STATUS,
        payload: "200",
      });
    }
  } catch (error) {
    console.log(error.message);
    dispatch({
      type: GRAPH_STATUS,
      payload: "500",
    });
  }
};
