export const handleOverlay = (type) => {
    const $body = document.querySelector('body');
    $body.style.overflow = type;
}
export const timeAgo = (date) => {
    const currentDate = new Date();
    const inputDate = new Date(date);
    const timeDifference = currentDate - inputDate;
    const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    if (daysAgo === 1) {
        return '1 day ago';
    } else if (daysAgo > 1) {
        return `${daysAgo} days ago`;
    } else {
        return 'Today';
    }
}

export const createHash = (string) => {
    let indexCount = 3
    const HashCode = process.env.REACT_APP_SECRET_CODE.split('')
    const stringCode = string.toUpperCase()
    for (const num of stringCode) {
        HashCode[indexCount] = num
        indexCount = indexCount + 3
    }
    return HashCode.join('')
}