import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import MyResponsiveBar from "./bar-graph";
import React, { useEffect, useState } from "react";
import ErrorGif from '../../static/img/error.gif'

const CommitGraphCard = ({ graphInfo, graphStatus }) => {
  const [status, setStatus] = useState("");
  useEffect(() => {
    if (graphStatus === "200") {
      if (graphInfo.length > 0) {
        setStatus("Commit monthly wise in each year");
      } else {
        setStatus("No Commits for analysis");
      }
    } else if (graphStatus === "500") {
      setStatus(
        "Sorry due to high count of commits you reached API rate limit"
      );
    } else {
      setStatus("We are calculating your commits");
    }
  }, [graphStatus]);

  return (
    <div className="p-2 lg:p-8 ">
      <p className="text-white  font-bold font-mona_sans_thin  leading-[20px] text-[12px] lg:leading-[20px] lg:text-[16px]">
        {status}
      </p>
      <div style={{background:graphStatus === "500" ? '#d2d2d2' : '#12181f'}} className="h-[30rem] rounded-xl border border-solid border-[#070707]shadow-[0px_0px_5px_0px_#FFFFFF29] mt-4 overflow-x-scroll md:overflow-hidden">
        <div className="w-[60rem] h-[400px] md:w-full md:h-full">
          {graphStatus === "500" ? (
            <div className="500">
             <img className="m-auto mt-14 pt-24 w-2/12" src={ErrorGif}/>
            </div>
          ) : (
            <>
              {" "}
              {graphInfo?.length > 0 ? (
                <MyResponsiveBar data={graphInfo} />
              ) : (
                <SkeletonTheme baseColor="#202020" highlightColor="#444">
                  <Skeleton className="h-full" />
                </SkeletonTheme>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommitGraphCard;
