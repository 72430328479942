import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";

import { RxCross2 } from "react-icons/rx";
import { FaCopy } from "react-icons/fa6";
import notifications from "../components/notifications";

const MyShareComponent = ({ url, setShare }) => {

  const handleCopy = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        notifications("success", "URL has copied")
      });
  };

  return (
    <div className="bg-[#232C36] inner_modal_body relative overlay">
     <div className="flex justify-between">
     <p className="text-white font-mona_sans_thin  leading-[20px] text-[20px] lg:leading-[30px] lg:text-[25px]">
        Share
      </p>
     <button
        className="  bg-secondary cross absolute justify-between top-[1rem] hover:bg-[#7bc585] w-[29px] rounded-full text-white p-[1px] lg:p-[2px] "
        onClick={() => setShare(false)}
      >
        <RxCross2 className="lg:w-[1rem] lg:h-[1rem]" />
      </button>
     
     </div>
      <div className="flex flex-col justify-center mt-4 ">
        <div className="flex justify-around mb-4">
          <FacebookShareButton url={url}>
            <FacebookIcon size={36} round={true} />
          </FacebookShareButton>
          <TwitterShareButton url={url}>
            <TwitterIcon size={36} round={true} />
          </TwitterShareButton>
          <WhatsappShareButton url={url}>
            <WhatsappIcon size={36} round={true} />
          </WhatsappShareButton>
          <TelegramShareButton url={url}>
            <TelegramIcon size={36} round={true} />
          </TelegramShareButton>
        </div>
        <div className="relative">
          <input
            className="mt-4 block w-full bg-gray-800 px-2 lg:px-4 py-1 lg:py-3 ps-4 lg:ps-8 text-[14px] lg:text-[18px] border border-emerald-300 text-emerald-300	 rounded-lg  	 outline-none"
            type="text"
            value={url}
          />
          <button
           onClick={() => handleCopy(url)}
            type="submit"
            className=" hover:text-white bg-gray-800 hover:bg-grey text-gray-900 absolute p-[5px] lg:p-[16px] transition-all duration-200 right-[1px] rounded-xl bottom-[2px] "
          >
            <FaCopy className="text-white lg:h-[1rem] lg:w-[1rem]" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MyShareComponent;
